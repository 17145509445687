@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.settings-menu {
  .am-title {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    padding: 0 16px;
    h3 {
      margin: 0 !important;
      color: #000;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      flex: 1;
    }
  }
  .ant-collapse,
  .ant-collapse-item,
  .ant-collapse-content {
    background: transparent !important;
    border: 0 !important;
  }
  .ant-collapse {
    .ant-collapse-header {
      padding: 9px 16px;
      font-weight: 500 !important;
      color: #000;
    }
    .ant-collapse-content-box {
      padding: 0 12px !important;
    }
  }
  .ant-menu.ant-menu-light {
    background: #fff !important;
  }
  .ant-menu {
    padding: 0 4px;
    border-right: 0 !important;
    > .ant-menu-item {
      padding: 0 16px !important;
      transition: none !important;
      .ant-menu-title-content,
      .ant-menu-title-content a,
      svg {
        transition: none !important;
      }
      &:hover {
        background: #ededec !important;
      }
    }
  }
  .ant-menu-item > svg {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    width: 18px;
    height: 18px;
    stroke-width: 1.5;
  }
  .ant-menu-item > .ant-menu-item-icon {
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item:not(.ant-menu-item-selected):active,
  .ant-menu-item:not(.ant-menu-item-selected):focus {
    background: #ededec !important;
    background-color: #ededec !important;
    color: $dark-1000 !important;
    .ant-menu-title-content,
    svg {
      color: $dark-1000 !important;
    }
  }
  .ant-menu-item.ant-menu-item-selected {
    .ant-menu-title-content {
      font-weight: 500 !important;
      color: #000;
    }
    svg {
      stroke-width: 2 !important;
      color: #000;
    }
  }
  .ant-menu-item .ant-menu-title-content {
    font-weight: 400;
  }
  .ant-menu.ant-menu-light {
    background: #fafaf9;
  }
  .ant-menu-inline-collapsed .ant-menu-item svg {
    position: relative;
    top: 11px;
    left: 5px;
  }
}
