@import "/src/_styleguide/variables.scss";

button.pp-table-button {
  font-family: $font-title;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 10px !important;
  svg {
    width: 14px;
    height: 14px;
    color: #757575 !important;
  }
  &[disabled],
  &[disabled]:hover {
    opacity: 0.3 !important;
  }
}

.pp-table-actions-drop {
  min-width: 160px !important;
  .ant-dropdown-menu-title-content > span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: $font-title;
    font-weight: 400;
    gap: 6px;
    svg {
      width: 14px;
      height: 14px;
      color: #757575 !important;
    }
    &.bad {
      color: #cd1309;
      svg {
        color: #cd1309 !important;
      }
    }
  }
}
