@import "/src/_styleguide/variables.scss";

.admin-import {
  display: flex;
  gap: 24px;
  .imp-options {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: flex-start;
    margin: 12px 0 24px 0;
    padding-bottom: 24px;
  }
}
.upload-doc {
  .ant-upload-wrapper {
    display: block;
    height: 200px;
  }
}
