@import "/src/_styleguide/variables.scss";

.admin-wizard-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 40px 24px 40px;
  height: 88px;
  min-height: 60px;
  border-top: 1px solid #ededed;
  background: #fafaf9;
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 240px);
  z-index: 999;
  transition: width 0.2s;
  > .awf-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }
  > .awf-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
}

.site-layout.collapsed {
  .site-content .admin-wizard-footer {
    width: calc(100% - 80px);
  }
}
