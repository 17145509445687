.mail-pdf-viewer {
  width: 100%;
  height: 453px;
  overflow: auto;

  background: #ececec;
  > .react-pdf__Document {
    background: #ececec;
    .react-pdf__Page {
      border-radius: 8px !important;
      overflow: hidden !important;
      margin-bottom: 12px;
      border: 1px solid #ddd;
      background: #ececec;
      box-shadow: inset 0px 0px 1px 1px #ddd;
    }
  }
}
