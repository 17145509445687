.wzrd-box {
  .wzrd-body.wzrd-schedule {
    padding: 0;
    .pp-table {
      margin-left: -1px;
      margin-right: -1px;
      margin-top: -1px;
      max-width: calc(100% + 2px);
      .ant-pagination {
        margin-top: 0;
        padding: 16px;
      }
    }
  }
}
