@import "/src/_styleguide/variables.scss";

.admin-settings {
  display: flex;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  position: absolute;
  overflow: hidden;
  .settings-menu {
    width: 240px;
    min-width: 240px;
    border-right: 1px solid #ececec;
    padding: 20px 0;
    background: #fff;
  }
  .settings-body {
    padding: 84px 24px 24px 24px;
    width: calc(100% - 240px);
    position: relative;
    &.need-scroll {
      padding: 60px 0 0 0;
    }
  }
  .settings-search {
    display: flex;
    align-items: center;
    position: fixed;
    top: 13px;
    right: 32px;
    z-index: 999;
    gap: 12px;
    .ms-filters {
      .ant-input-group-wrapper {
        max-width: 240px;
      }
    }
    .ms-actions {
      button {
        font-family: $font-title;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        border-radius: 10px !important;
        height: 36px !important;
        svg {
          width: 14px;
          height: 14px;
          color: #757575 !important;
        }
      }
    }
  }
  .ant-pagination {
    margin: 0 -40px;
    align-items: center;
    justify-content: flex-end;
    background: #fff;
    border-top: 1px solid #ededed;
    padding: 16px 72px 16px 140px;
    position: fixed;
    bottom: 0;
    width: 100%;
    right: 0;
    width: calc(100% - 200px);
    transition: width 0.2s;
  }
}
