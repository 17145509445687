@import "/src/~@centrate-io/barn/dist/variables";

.company-container {
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
  .company-drop {
    cursor: pointer;
    margin: 9px 8px;
    border-radius: 8px;
    height: 36px;
    align-items: center;
    padding: 0 12px;
    flex: 1;
    width: calc(100% - 16px);
    > div {
      width: 24px;
      height: 24px;
      background: $dark-1000;
      color: #fff;
      border-radius: 4px;
      display: flex;
      font-weight: 700;
      font-size: 16px;
      margin-right: 8px;
      min-width: 24px;
    }
    img.top-logo {
      display: flex;
      width: 24px;
      height: 24px;
      max-width: 24px;
      max-height: 24px;
      overflow: hidden;
      border-radius: 4px;
      margin-right: 8px;
    }
    h3 {
      flex: 1;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
    }
    &:hover {
      background: $dark-100;
    }
    &.drop-collapsed {
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      justify-content: center;
      > div {
        margin-right: 4px;
      }
    }
  }
}

.company-drop-drop {
  min-width: 260px !important;
  margin-left: 4px;
  padding-top: 1px;
  .no-show {
    display: none !important;
  }
  .switch-company-container {
    &:hover,
    &.ant-dropdown-menu-item-active {
      background-color: transparent !important;
      cursor: default !important;
    }
    .switch-company {
      > h5 {
        margin: 0;
        color: $dark-400;
        font-size: 10px;
        line-height: 12px;
      }
      .company-select {
        padding: 6px 8px;
        margin: 4px -8px 0 -8px;
        border-radius: 4px;
        cursor: pointer;
        h5 {
          margin: 0;
          font-size: 12px;
        }
        p {
          margin: 0;
          color: $dark-400;
          font-size: 11px;
          line-height: 11px;
          i {
            text-transform: capitalize;
          }
        }
        &:hover {
          background: #ededed;
        }
      }
    }
  }
  .drop-user-container {
    &:hover,
    &.ant-dropdown-menu-item-active {
      background-color: transparent !important;
      cursor: default !important;
    }
    .drop-user {
      padding: 8px 0;
      h5 {
        margin: 0;
        font-size: 14px;
        span {
          text-transform: capitalize;
          margin-left: 8px;
        }
      }
      p {
        margin: 0;
        color: $dark-400;
        font-size: 12px;
        line-height: 12px;
      }
      > div {
        color: $dark-700;
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        margin-bottom: 2px;
      }
    }
  }
}
