@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.credit-select-page {
  > h2 {
    margin-bottom: 16px;
  }
  .credit-select-info {
    color: #000;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
}

.wzrd-trade-footer {
  position: fixed;
  bottom: 44px;
  right: 182px;
  z-index: 9999 !important;
  border-right: 1px solid #d6d6d6;
  padding-right: 16px !important;
  margin-right: 16px !important;
  transform: translateY(50%);
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  > div {
    color: #000;
    text-align: right;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    b {
      font-weight: 600;
    }
    margin: 0;
  }
  > h5 {
    text-align: right;
    color: #4a9b19;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    margin: 0;
  }
}

.admin-wizard-credit .wzrd-box {
  .wzrd-header {
    .circle {
      display: flex;
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      background: #c5efab;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      margin-right: 4px;
      svg {
        width: 14px;
        height: 14px;
        stroke-width: 3;
      }
      &.danger {
        background: #f9d2ce;
      }
    }
  }

  .alert-box {
    display: flex;
    padding: 16px 12px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    border-radius: 12px;
    background: #fcefbf;
    margin-bottom: 24px;
    &.danger {
      border: 1px solid #f9d2ce;
      background: #fceae8;
    }
  }

  .wzrd-body.declined {
    .declined-desc {
      color: #757575;
      margin-bottom: 24px;
    }
    button {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        stroke-width: 1;
        margin-left: 8px;
        width: 18px;
        height: 18px;
        position: relative;
        top: -1px;
      }
    }
  }

  .credit-total {
    margin-top: 16px;
    > span {
      font-weight: 400;
      color: #9d9d9d;
    }
  }

  .bottom-actions {
    margin-top: 24px;
  }

  .credit-list {
    margin: 0 0 0 0;
    > .tradeline {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      border-bottom: 1px solid #e0e0e0;
      padding: 20px 0 16px 0;
      &:first-child {
        padding-top: 0;
      }
      label {
        margin-top: 2px !important;
      }
      > .account-box {
        flex: 1;
        margin-left: 16px;
        .top-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #000;
          font-family: $font-title;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          .ant-tag {
            margin-left: 8px;
            position: relative;
            top: -1px;
          }
        }
        .bottom-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #757575;
          font-family: $font-title;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 4px;
        }
        .select-actions {
          margin-top: 20px;
          text-align: right;
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
          > button {
            flex: 1;
          }
        }
      }
      &:last-child > .account-box {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}
