@import "/src/_styleguide/variables.scss";

.settings-workflows {
  .workflows {
    flex: 1;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    table {
      width: auto;
    }
    .ant-pagination {
      margin: 0 -40px;
      align-items: center;
      justify-content: flex-end;
      background: #fff;
      border-top: 1px solid #ededed;
      padding: 16px 72px 16px 140px;
      position: fixed;
      bottom: 0;
      width: 100%;
      right: 0;
      width: calc(100% - 200px - 266px);
      transition: width 0.2s;
    }
    .workflow-actions button.barn-btn.btn-tiny {
      font-weight: 500 !important;
    }
    .workflow-actions svg {
      stroke-width: 1.5;
    }
    .workflow-name {
      color: #000;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-bottom: 2px;
    }
    .helper.workflow-helper {
      color: #757575;
      font-family: $font-title;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 146.154% */
    }
  }
  .workflows-top {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    margin-bottom: 24px;
    .wf-icon {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: #ececec;
      svg {
        color: #000;
        width: 20px;
        height: 20px;
      }
    }
    .wf-info {
      h2 {
        margin: 0 !important;
        color: #000;
        font-family: $font-title;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 135.714% */
      }
      p {
        margin: 0 !important;
        color: #757575;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
      }
    }
  }
}
