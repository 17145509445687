@import "/src/~@centrate-io/barn/dist/variables";

input.barn-autocomplete {
  font-family: "PP Mori", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  padding: 0 12px;
  height: 40px;
  line-height: 40px;
  outline: 0;
  box-shadow: none;
  border-radius: 6px;
  background: #ffffff;
  border: 2px solid transparent;
  box-shadow: 0 0 0 1px #ebebea;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #151414;
  overflow: hidden;
  &::placeholder {
    color: #a5a4a1;
  }
  &:hover {
    border: 2px solid #151414;
    box-shadow: none;
  }
  &:focus {
    border: 2px solid #151414;
    background: #ffffff;
    box-shadow: none;
  }
  &[disabled] {
    border: 1px solid #f5f7fb;
    background: #f5f7fb;
    color: #adb5c5;
    &::placeholder {
      color: #adb5c5;
    }
  }
  &.pp-invalid {
    border-color: $error !important;
  }
  &.pp-large {
    padding: 0 16px;
    height: 56px;
    line-height: 56px;
    border-radius: 12px;
  }
  &.pp-medium {
    padding: 0 16px;
    height: 48px;
    line-height: 48px;
    border-radius: 12px;
  }
}

.barn-dark {
  input.barn-autocomplete {
    background: #151414;
    border: 2px solid transparent;
    box-shadow: 0 0 0 1px #3e3e3c;
    color: #ffffff;
    &::placeholder {
      color: #686764;
    }
    &:hover {
      border: 2px solid #ffffff;
      box-shadow: none;
    }
    &:focus {
      border: 2px solid #ffffff;
      background: #151414;
      box-shadow: none;
    }
  }
}
