@import "/src/~@centrate-io/barn/dist/variables";

.admin-application-snapshot {
  svg.tabler-icon-circle-check {
    color: $success;
  }
  svg.tabler-icon-circle-minus {
    color: $dark-200;
  }
  svg.tabler-icon-circle-x {
    color: $error;
  }
  .admin-search button {
    height: 36px;
    line-height: 36px;
  }
  .admin-search .ant-select {
    width: 160px;
  }
}
