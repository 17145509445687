@import "/src/_styleguide/variables.scss";

.offer-iw {
  .plan-includes {
    margin-top: 32px;
    padding: 0 16px;
    h5 {
      color: #000;
      font-family: $font-title;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      letter-spacing: 0.96px;
      text-transform: uppercase;
      margin: 0 0 16px 0;
    }
    .bullets {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      > .bullet {
        display: flex;
        align-items: center;
        flex: 0 0 100%;
        color: #000;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 8px;
        text-transform: capitalize;
        svg {
          color: #83d1d6;
          width: 14px;
          height: 14px;
          margin-right: 12px;
        }
      }
    }
  }
  button {
    margin-top: 24px;
    background: #83d1d6 !important;
    border: 1px solid #83d1d6 !important;
    color: #000 !important;
    font-family: $font-title;
    font-weight: 500;
    &.barn-btn:hover {
      background: #73c1c6 !important;
      border: 1px solid #73c1c6 !important;
    }
  }
  &.duration-18 {
    .bullets > .bullet svg {
      color: #b9abef;
    }
    button {
      background: #b9abef !important;
      border: 1px solid #b9abef !important;
      &.barn-btn:hover {
        background: #a99bdf !important;
        border: 1px solid #a99bdf !important;
      }
    }
  }
  &.duration-12 {
    .bullets > .bullet svg {
      color: #a2d683;
    }
    button {
      background: #a2d683 !important;
      border: 1px solid #a2d683 !important;
      &.barn-btn:hover {
        background: #92c673 !important;
        border: 1px solid #92c673 !important;
      }
    }
  }
}
