@import "/src/_styleguide/variables.scss";

.step-offers {
	.app-form.wide {
		margin-left: -177px;
		margin-right: -177px;
	}
	.offer-cards {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		gap: 16px;
		margin-bottom: 56px;
		.pp-offer-card {
			flex: 1;
		}
	}
	> h2 {
		margin: 0;
	}
	.offer-select-info {
		margin: 16px 0 32px 0;
	}
	.mobile {
		display: none;
	}
	.desktop {
		display: flex;
	}
	.app-faq h4 {
		color: #000;
		font-family: $font-title;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-bottom: 16px;
		margin-top: 0;
	}
	.offer-includes {
		padding: 24px;
		border-radius: 16px;
		background: #f8f6f2;
		margin-top: 8px;
		margin-bottom: 32px;
		margin-left: -177px;
		display: none !important;
		margin-right: -177px;
		h5 {
			color: #000;
			font-family: $font-title;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 18px; /* 150% */
			letter-spacing: 0.96px;
			text-transform: uppercase;
			margin: 0 0 16px 0;
		}
		.bullets {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			> .bullet {
				display: flex;
				align-items: center;
				flex: 0 0 33.3333%;
				color: #000;
				font-family: $font-title;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 150% */
				text-transform: capitalize;
				margin-bottom: 8px;
				svg {
					color: #83d1d6;
					width: 14px;
					height: 14px;
					margin-right: 12px;
				}
			}
		}
	}
}

@media (max-width: $breakpoint-large) {
	.step-offers {
		.app-form.wide {
			margin-left: 0;
			margin-right: 0;
		}
		.offer-cards {
			display: flex;
			.pp-offer-card {
				flex: 1;
			}
		}
		.mobile {
			display: block;
		}
		.desktop {
			display: none;
		}
		.offer-includes {
			margin-left: 0;
			margin-right: 0;
			.bullets > .bullet {
				flex: 0 0 100%;
			}
		}
	}
}

@media (max-width: $breakpoint-mobile) {
	.step-offers {
		.offer-includes {
			display: none;
		}
	}
}
