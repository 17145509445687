@import "/src/~@centrate-io/barn/dist/variables";

.admin-companies {
  svg.tabler-icon-circle-check {
    color: $success;
  }
  svg.tabler-icon-circle-minus {
    color: $dark-200;
  }
  svg.tabler-icon-circle-x {
    color: $error;
  }

  .doc-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .doc-owner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    i {
      font-size: 13px;
    }
  }
  .doc-download {
    &:hover {
      text-decoration: underline;
    }
  }
  .admin-toggle {
    b {
      font-weight: 500;
    }
    button {
      margin-left: 8px;
    }
    margin-right: 16px;
  }
}
