.task-template-tabs {
  position: relative;
  top: -25px;
  .tab-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      color: #9d9d9d;
    }
    &.call svg {
      color: #a2d683;
    }
    &.general svg {
      color: #d7c0e5;
    }
    &.email svg {
      color: #a1d3e2;
    }
    &.text svg {
      color: #fcdeb0;
    }
    &.mail svg {
      color: #000;
    }
  }
  .ant-tabs-nav {
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 24px;
  }
  .ant-tabs.call .ant-tabs-content-holder,
  .ant-tabs.general .ant-tabs-content-holder,
  .ant-tabs.email .ant-tabs-content-holder,
  .ant-tabs.text .ant-tabs-content-holder,
  .ant-tabs.mail .ant-tabs-content-holder {
    position: absolute;
    top: 46px;
    height: calc(100vh - 106px);
    width: calc(100vw - 480px);
    overflow-y: scroll;
    margin-left: -24px;
    margin-right: -24px;
    padding: 24px;
    > .ant-tabs-content {
      position: relative;
      min-height: 100%;
    }
  }
  .admin-toggle {
    display: inline-block;
    b {
      font-weight: 500;
    }
    button {
      margin-left: 8px;
    }
    margin-right: 16px;
  }
}
