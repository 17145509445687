@import "/src/_styleguide/variables.scss";

.create-template-modal {
  .ct-preview {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ededed;
    > span {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 22px;
      right: 16px;
      gap: 12px;
      font-weight: 400;
      font-family: $font-title;
      font-size: 13px;
      font-style: normal;
      line-height: normal;
    }
    .html-preview {
      padding: 0;
    }
    .quill-preview {
      padding: 40px;
      > div {
        p,
        ol,
        ul,
        pre,
        blockquote,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    .email-preview {
      display: flex;
      flex-direction: column;

      padding: 40px;
      white-space: pre-wrap;
    }
    .text-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px;
      .message {
        max-width: 70%;
        padding: 10px;
        margin: 14px 0 10px 0;
        border-radius: 15px;
        position: relative;
        font-size: 14px;
        line-height: 18px;
        z-index: 999;
        margin-top: 14px;
        white-space: pre-wrap;
        > div {
          position: absolute;
          top: -15px;
          font-size: 12px;
          line-height: 12px;
          color: #757575;
        }
      }

      /* Right (blue) bubble */
      .message.right {
        background-color: #007aff;
        color: white;
        margin-right: 10px;
        > div {
          right: 10px;
        }
      }

      /* Tail for left (grey) bubble */
      .message.right {
        &:before {
          content: "";
          position: absolute;
          z-index: -1;
          bottom: 0;
          right: -8px;
          height: 20px;
          width: 20px;
          background: #007aff;
          border-bottom-left-radius: 15px;
        }

        &:after {
          content: "";
          position: absolute;
          z-index: 1;
          bottom: 0;
          right: -10px;
          width: 10px;
          height: 20px;
          background: white;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
}
