@import "/src/_styleguide/variables.scss";

.select-trigger {
  .ant-collapse .ant-collapse-content {
    background-color: transparent;
  }
  > .option-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    > .ob-label {
      color: #000;
      font-family: $font-title;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 80px;
      max-width: 80px !important;
      min-width: 80px !important;
    }
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      font-size: 13px !important;
    }
    .ant-select-selection-item {
      font-size: 13px !important;
      color: #000;
      font-family: $font-title;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .ant-select-selection-item-content {
      display: flex;
      align-items: center;
    }
    .ant-select-multiple {
      min-height: 40px !important;
      height: auto !important;
      .ant-select-selector {
        min-height: 40px !important;
        height: auto !important;
      }
    }
  }
}
