@import "/src/_styleguide/variables.scss";

.bar-mail {
  width: 540px;
  max-width: 540px;
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 50px 0 0 0;
  box-shadow:
    0px 58px 16px 0px rgba(0, 0, 0, 0),
    0px 37px 15px 0px rgba(0, 0, 0, 0.01),
    0px 21px 13px 0px rgba(0, 0, 0, 0.02),
    0px 9px 9px 0px rgba(0, 0, 0, 0.04),
    0px 2px 5px 0px rgba(0, 0, 0, 0.04);
  bottom: 64px;
  right: calc((100% - 240px) / 2);
  transform: translateX(50%);
  transition:
    right 0.2s,
    bottom 0.3s linear,
    opacity 0.3s ease-in-out,
    padding 0.3s ease-in-out,
    max-width 0.3s ease-in-out,
    width 0.3s ease-in-out,
    min-width 0.3s ease-in-out,
    height 0.3s ease-in-out;
  overflow: hidden;
  opacity: 1;
  height: 326px;
  &.draft-mode {
    height: 700px;
    .address-card > .ac-hover {
      display: none !important;
    }
  }
  &.preview-mode {
    height: 700px;
  }
  .mail-addresses {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-bottom: 1px solid #d9d9d9;
    > div {
      border-right: 1px solid #d9d9d9;
      width: 50%;
      &:last-child {
        border-right: 0;
      }
    }
  }
  .complete-mail-task {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .mail-template {
    border-bottom: 1px solid #d9d9d9;
    padding: 16px;
    label {
      font-size: 13px;
      margin-bottom: 6px;
      color: #757575;
    }
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    font-size: 14px !important;
  }
  .mail-window {
    display: flex;
    flex-direction: column;
    padding: 0 0;
    width: 100%;
    input {
      font-size: 14px;
    }
  }
  &.minimizing {
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    bottom: 40px !important;
    opacity: 0.2 !important;
  }
  &.minimize {
    display: none !important;
  }
  .mail-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 12px;
  }
  .mail-preview {
    height: 469px;
    padding: 16px 16px 0 16px;
    border-bottom: 1px solid #d9d9d9;
    background: #ececec;
    position: relative;
    .ant-spin {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .ant-spin svg {
      color: #cacaca;
    }
  }
  .mail-html-preview {
    height: 375px;
    padding: 16px 16px 0 16px;
    border-bottom: 1px solid #d9d9d9;
    background: #ececec;
    position: relative;
    .ant-spin {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .ant-spin svg {
      color: #cacaca;
    }
  }
  .pdf-failed {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      font-family: $font-main;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #000;
      text-align: center;
    }
    button {
      margin-top: 12px;
    }
  }
  .dialer-close {
    display: flex;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    z-index: 999;
    background: #fff;
    &:hover {
      background: #d9d9d9;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.site-layout.collapsed .bar-mail {
  right: calc((100% - 80px) / 2);
}
