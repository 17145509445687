@import "/src/_styleguide/variables.scss";

.ba-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  padding: 32px 32px;
  width: calc(100vw - 64px);
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  .bank-added {
    width: 100%;
    position: relative;
    max-width: 400px !important;
    display: flex;
    padding: 48px 56px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 0px 0px 4px #f3f3f3;
    background: #fff;
    overflow: hidden;
    margin: 0;
    .gradient {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 165px;
      background: linear-gradient(
        180deg,
        rgba(1, 194, 170, 0.5) 0%,
        rgba(1, 192, 168, 0.24) 39.16%,
        rgba(0, 178, 156, 0) 63.16%
      );
    }
    img {
      width: 202px;
      height: 88px;
    }
    h2 {
      color: #000;
      text-align: center;
      font-family: $font-title;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 50px;
      margin: 28px 0 16px 0;
    }
    p {
      color: #000;
      text-align: center;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

// @media (max-width: $breakpoint-mobile) {
//   .ba-wrapper {
//     position: absolute !important;
//     left: 50%;
//   }
// }
.step-bank {
  > h2 {
    margin: 0;
  }
  p.final-info {
    margin: 16px 0 32px 0;
    color: #000 !important;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    b {
      font-weight: 500;
    }
  }
  .line-item {
    .due-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #000;

      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin: 0 0 8px 0;
    }
  }
  .final-actions {
    flex: 1;
    .due-amount {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      > div {
        color: #000;
        font-family: $font-title;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }
  .final-trust {
    display: flex;
    align-items: flex-start;
    color: #000;
    font-family: $font-main;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.05px;
    p {
      margin: 0;
    }
    svg {
      color: #000;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      min-width: 18px;
    }
    b {
      font-weight: 700;
    }
  }
  .info-box {
    padding: 16px 12px;
    border-radius: 12px;
    border: 1px solid #83d1d6;
    background: #e8f6f7;
    display: flex;
    align-items: flex-start;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-top: 16px;
    p {
      margin: 0;
    }
    svg {
      color: #5ab5bb;
      width: 20px;
      height: 20px;
      margin-right: 12px;
      min-width: 20px;
    }
    b {
      font-weight: 500;
    }
  }
  p.final-terms {
    color: #000;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.18px;
    b {
      font-weight: 500;
    }
    a {
      font-weight: 500;
      color: #000;
    }
  }
  .bottom-actions {
    margin-top: 40px;
  }
  button.confirm-desktop {
    display: flex;
  }
  button.confirm-mobile {
    display: none;
  }
  &.step-review {
    .final-info {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .step-register {
    .pp-avatar {
      display: none;
    }
    .bottom-actions {
      margin-top: 0;
      .final-actions {
        width: 100%;
      }
      .final-terms {
        display: none;
      }
    }
    button.confirm-desktop {
      display: none;
    }
    button.confirm-mobile {
      display: flex;
    }
  }
}
