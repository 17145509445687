@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.settings-integrations {
  position: relative;
  min-height: 600px;
  overflow-y: scroll;
  max-height: 100%;
  padding: 24px 24px 56px 24px;
  .int-section {
    > h3 {
      color: #000;
      font-family: $font-title;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin: 0 0 4px 0;
    }
    > p {
      color: #757575;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }
    .int-options {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: flex-start;
      margin: 12px 0 24px 0;
      padding-bottom: 24px;
    }
  }
}
