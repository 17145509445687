@import "/src/_styleguide/variables.scss";

.body-accounts {
  > .account {
    display: flex;
    gap: 12px;
    border-bottom: 1px solid #ececec;
    padding: 12px 16px;
    position: relative;
    &:last-child {
      border-bottom: 0;
    }
    .a-logo {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid #d6d6d6;
      background: #fff;
      box-shadow:
        0px 7.143px 4.286px 0px rgba(0, 0, 0, 0.03),
        0px 2.857px 2.857px 0px rgba(0, 0, 0, 0.04),
        0px 1.429px 1.429px 0px rgba(0, 0, 0, 0.05);
      svg {
        width: 24px;
        height: 24px;
      }
      img {
        width: 32px;
        height: 32px;
      }
    }
    .a-info {
      flex: 1;
      .a-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        h4 {
          color: #000;
          font-family: $font-title;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          margin: 0;
        }
        > div {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #000;
          text-align: right;
          font-family: $font-title;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          svg {
            width: 16px;
            height: 16px;
            color: #757575;
          }
        }
      }
    }
    .a-tag {
      .atg {
      }
    }
    .a-actions {
      display: none;
      gap: 4px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      border-radius: 12px;
      background: #f3f3f3;
      .a-info .a-row > div {
        display: none;
      }
      .a-actions {
        display: flex;
      }
    }
  }
}
