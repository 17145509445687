.admin-application-information {
  margin: 24px 32px;
  .admin-information-boxes {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-picker-input input {
    font-size: 16px !important;
  }
}
