@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.admin-application-conversation {
  .convo-open {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    align-items: center;
    border: 1px solid #ececec;
    background: #fff;
    border-radius: 12px;
    .convo-actions {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 12px;
      height: 32px;
      button {
        display: none;
      }
    }
    &:hover {
      .convo-actions {
        button {
          display: flex;
        }
      }
    }
    .convo-preview {
      width: 100%;
      padding: 0;
      max-width: 100%;
      overflow: scroll;
    }
    .co-header {
      display: flex;
      width: 100%;
      .pp-user-avatar,
      .pp-company-avatar {
        margin-right: 12px;
      }
      .convo-info {
        flex: 1;
        min-width: 0;
        h5 {
          margin: 0;
          color: #000;
          font-family: $font-title;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          white-space: nowrap; /* Prevent text from wrapping */
          overflow: hidden; /* Hide the overflowed content */
          text-overflow: ellipsis; /* Show the ellipsis when the text overflows */
          max-width: 100%;
        }
        p {
          margin: 0;
          color: #757575;
          font-family: $font-title;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          b {
            font-weight: 500;
          }
        }
      }
      .convo-date {
        margin-left: 8px;
        color: #000;
        text-align: right;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 4px;
      }
    }
  }
}
