@import "/src/_styleguide/variables.scss";

.exp-drawer {
  border-radius: 16px 0px 0px 16px;
  overflow: hidden;
  .ant-drawer-header {
    display: none !important;
  }
  .ant-drawer-body {
    padding: 0;
    position: relative;
    .header {
      position: absolute;
      top: 0;
      left: 0;
      height: 46px;
      width: calc(100% - 40px);
      border-bottom: 1px solid #ececec;
      padding: 24px 16px 16px 24px;
    }
    .report {
      padding: 24px 32px 24px 20px;
      position: absolute;
      right: 0;
      top: 86px;
      height: 86px;
      width: calc(100% - 304px);
      position: absolute;
      height: calc(100% - 86px - 48px);
      overflow-y: scroll;
    }
  }
}

.ant-drawer.ant-drawer-open {
  z-index: 999999 !important;
}

.exp-drawer .wzrd-body {
  position: relative;
  button {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    font-weight: 500 !important;
    font-family: $font-title;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  .trade-delinquency {
    margin-top: 24px;
    h4 {
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      margin: 0 0 8px 0;
    }
  }
  .trade-condition {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 37px;
    background: #abebef;
    position: absolute;
    top: 24px;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    right: 32px;
    &.trade-C {
      background: #f9d2ce;
    }
    &.trade-P {
      background: #fcefbf;
    }
  }
  .trade-header {
    h3 {
      color: #000;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }
    p {
      color: #757575;
      font-family: $font-title;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
      text-transform: uppercase;
    }
    div {
      color: #000;
      font-family: $font-title;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      b {
        font-weight: 500;
      }
    }
  }
  .trade-box {
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #ececec;
    background: #f6f6f6;
    margin: 16px 0;
  }
  .trade-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    column-gap: 24px;
    > div {
      flex-basis: calc(50% - 12px);
      border-bottom: 1px solid #ececec;
      padding: 12px 0;
      h5 {
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        margin: 0 0 2px 0;
      }
      p {
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
      }
    }
  }
  .trade-box > .trade-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid #ececec;
    &:last-child {
      border-bottom: 0;
    }
    > .trade-item {
      flex: 1;
      padding: 8px 0;
      h5 {
        color: #000;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin: 0 0 2px 0;
      }
      p {
        color: #000;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
      }
    }
  }
}

.exp-drawer .wzrd-body {
  padding: 16px 32px 24px 32px !important;
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 6px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #ececec;
    overflow: hidden;
    margin-bottom: 16px;
    th,
    td {
      border: 1px solid #ececec;
      text-align: left;
      padding: 8px 16px;
    }
    th {
      background: #f6f6f6;
    }
  }
  .columns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 12px;
    > .info-line,
    > .info-vert {
      flex-basis: calc(50% - 6px);
    }
  }
  .info-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 0;
    border-bottom: 1px solid #ececec;
    &.last {
      border-bottom: 0;
    }
    > h5 {
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      margin: 0;
    }
    > div {
      > div {
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        text-align: right;
        margin: 0;
      }
      > p {
        color: #757575;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: right;
        margin: 0;
      }
    }
  }
  .info-vert {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0;
    border-bottom: 1px solid #ececec;
    > h5 {
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      margin: 0 0 4px 0;
    }
    &.last {
      border-bottom: 0;
    }
    > div {
      .columns {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        gap: 12px;
        > .item-list {
          flex-basis: calc(50% - 6px);
        }
      }
      .item-list {
        > div {
          color: #000;
          font-family: $font-title;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          margin: 0 0 2px 0;
        }
        > p {
          color: #757575;
          font-family: $font-title;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          margin: 0 0 2px 0;
        }
      }
    }
  }
}
