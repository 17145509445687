@import "/src/_styleguide/variables.scss";

.step-phone {
  .small-note {
    color: #757575;
    font-family: $font-main;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.05px;
    margin-top: 6px;
  }
}
