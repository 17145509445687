@import "/src/~@centrate-io/barn/dist/variables";

.pp-search {
  &.ant-input-search .ant-input {
    border-color: #dedddc;
  }
  &.ant-input-search .ant-input-search-button {
    height: 36px;
    width: 36px;
    border-color: #dedddc;
    svg {
      color: $dark-300;
    }
  }
  &.ant-input-search .ant-input:hover,
  &.ant-input-search .ant-input:focus {
    border-color: $dark-600;
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $dark-600;
    border-color: $dark-600;
  }
  &.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary):hover {
    color: $dark-600;
  }
  .ant-input:focus,
  .ant-input:focus-within {
    box-shadow: none !important;
    outline: 0;
  }
  &.ant-input-search
    .ant-input:hover
    + .ant-input-group-addon
    .ant-input-search-button:not(.ant-btn-primary),
  &.ant-input-search
    .ant-input:focus
    + .ant-input-group-addon
    .ant-input-search-button:not(.ant-btn-primary) {
    border-inline-start-color: $dark-600;
  }
  .ant-input {
    font-family: $font-major;
    color: $dark-600;
    padding-top: 7px;
    padding-bottom: 5px;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-family: $font-major;
    }
  }
  .ant-input-affix-wrapper {
    height: 36px !important;
  }
  .ant-input-affix-wrapper.ant-input-outlined:focus,
  .ant-input-affix-wrapper.ant-input-outlined:focus-within {
    border-color: $dark-600 !important;
    box-shadow: none !important;
    outline: 0;
    background-color: #ffffff;
  }
  .ant-input-affix-wrapper.ant-input-outlined:hover {
    border-color: $dark-600 !important;
    background-color: #ffffff;
  }
}
