@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.settings-manage {
  position: relative;
  min-height: 600px;
  overflow-y: scroll;
  max-height: 100%;
  padding: 24px 24px 56px 24px;
  .logo-box {
    display: inline-flex;
    flex-direction: column;
    img {
      display: block;
      width: 180px;
      height: 180px;
      max-width: 180px;
      max-height: 180px;
      overflow: hidden;
      border-radius: 8px;
      margin-bottom: 12px;
    }
    button {
      max-width: 120px;
      text-align: center;
      justify-content: center;
    }
  }
  .manage-box {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #cfcfcf;
    background: #fff;
    margin-bottom: 20px;
    overflow: hidden;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      box-shadow: 0px 0px 0px 4px #f3f3f3;
    }
    .manage-header {
      display: flex;
      padding: 16px 24px 12px 24px;
      gap: 8px;
      border-bottom: 1px solid #ececec;
      align-items: center;
      > h3 {
        color: #000;
        font-family: $font-title;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
        margin: 0;
        flex: 1;
      }
      > p {
        color: #949494;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
      }
      &.custom {
        cursor: pointer;
        &:hover {
          background: #f7f8f9;
        }
      }
    }
    .manage-body {
      padding: 24px 40px 40px 40px;
      .form-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        > div {
          flex: 1;
        }
      }
      .form-item,
      .form-row {
        margin-bottom: 16px;
      }
      .ant-checkbox-wrapper {
        margin-top: 8px;
      }
      > .app-yesno {
        border-bottom: 1px solid #ececec;
        padding-bottom: 20px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
}
