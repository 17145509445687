@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.admin-application-mail {
  margin: 24px 32px;

  svg.tabler-icon-circle-check {
    color: $success;
  }
  svg.tabler-icon-circle-minus {
    color: $dark-200;
  }
  svg.tabler-icon-circle-x {
    color: $error;
  }
  .call-desc {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    .pp-user-avatar,
    .pp-company-avatar {
      margin-right: 0;
    }
    .call-name {
      color: #000;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .call-task {
    color: #000;
    font-family: $font-title;
    font-weight: 500;
  }
}
