@import "/src/_styleguide/variables.scss";

.admin-analytics {
  display: flex;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  position: absolute;
  overflow: hidden;
  .analytics-menu {
    width: 240px;
    min-width: 240px;
    border-right: 1px solid #ececec;
    padding: 20px 0;
    background: #fff;
  }
  .analytics-body {
    padding: 84px 24px 24px 24px;
    width: calc(100% - 240px);
  }
}
