@import "/src/_styleguide/variables.scss";

.pp-alert-box {
  padding: 16px 16px;
  border-radius: 12px;
  border: 1px solid #edebe9;
  background: #f7f6f5;
  color: #000;
  font-family: $font-title;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  b {
    font-weight: 500;
  }

  &.pp-alert-box-danger {
    border: 1px solid #f9d2ce;
    background: #fceae8;
  }
  &.pp-alert-box-warning {
    border: 1px solid #fcefbf;
    background: #fefaec;
  }
  &.pp-alert-box-info {
    border: 1px solid #83d1d6;
    background: #e8f6f7;
  }
}
