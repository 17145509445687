@import "/src/_styleguide/variables.scss";

.bottom-actions {
  margin-top: 64px;
  > .action-buttons {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    > a {
      flex: 1;
    }
  }
  > .powered {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #9d9d9d;
    margin-top: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    img {
      margin-left: 4px;
    }
  }
  .footer-logo {
    text-align: center;
    margin-top: 12px;
    display: none;
    img {
      height: 22px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .bottom-actions {
    border-top: 1px solid #d7d7d7;
    &:not(.no-mobile) {
      position: fixed;
      bottom: 0;
      margin: 0;
      width: calc(100% - 48px);
      left: 0;
      padding: 20px 24px 16px 24px;
      background: #fff;
      background: #fff;
      box-shadow: 0px 4px 16px 8px rgba(0, 0, 0, 0.08);
      z-index: 999;
    }
    .footer-logo {
      display: block;
    }
  }
}
