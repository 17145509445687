@import "/src/_styleguide/variables.scss";

.plaid-bank {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
	img {
		width: 24px;
		height: 24px;
	}
	.bank-info {
		color: #000;
		font-family: $font-title;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}
}
