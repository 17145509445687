@import "/src/_styleguide/variables.scss";

.ab-drawer {
  border-radius: 16px;
  overflow: hidden;
  .ant-drawer-header {
    display: none !important;
  }
  .ant-drawer-body {
    padding: 0;
    position: relative;
    .ab-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      .ab-header {
        height: 200px;
        flex-shrink: 0;
        padding: 32px;
        display: flex;
        flex-direction: column;
        .ab-contact {
          flex: 1;
          display: flex;
          align-items: flex-start;
          padding: 20px 16px;
          .ab-box {
            flex: 1;
            h5 {
              color: #78716c;
              font-family: $font-main;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
              letter-spacing: -0.12px;
              margin: 0 0 5px 0;
            }
            p {
              color: #000;
              font-family: $font-main;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 133.333% */
              letter-spacing: -0.36px;
              margin: 0;
              padding: 0;
            }
          }
        }
        .ab-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          margin-bottom: 24px;
          .ab-logo {
            display: flex;
            width: 56px;
            height: 56px;
            padding: 0;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            border: 0.7px solid #bfbfbf;
            background: #f5f5f4;
            box-shadow: 0px 2.8px 2.8px 0px rgba(0, 0, 0, 0.1);
            font-family: $font-title;
            font-size: 28px;
            font-weight: 500;
            color: #000;
          }
          .ab-name {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            h2 {
              color: #000;
              font-family: $font-main;
              font-size: 28px;
              font-style: normal;
              font-weight: 600;
              line-height: 32px; /* 114.286% */
              letter-spacing: -0.28px;
              margin: 0 0 0 0;
              padding: 0;
            }
            p {
              color: #757575;
              font-family: $font-title;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 128.571% */
              margin: 0;
              padding: 0;
            }
          }
        }

        // <div className="ab-info">
        //     <div className="ab-logo">J</div>
        //     <div className="ab-name">
        //       <h2>JP Morgan, Chase</h2>
        //       <p>1234567890</p>
        //     </div>
        //   </div>
      }

      .ab-content {
        flex: 1; // Fills the remaining space
        overflow-y: auto; // Optional: Adds scroll if content overflows
        background: #fafaf9;
      }
    }
  }
}

.ab-parent {
  .ant-drawer-content-wrapper {
    border-radius: 16px;
    height: calc(100% - 24px);
    top: 12px;
    right: 12px;
  }
}

.ab-parent.ant-drawer.ant-drawer-open {
  z-index: 1001 !important;
}
