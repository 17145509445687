@import "/src/_styleguide/variables.scss";

.pp-info-window {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  max-width: 100%;
  .pp-window-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    content: "";
    clear: both;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    filter: blur(4px);
  }
  .pp-window-box {
    padding: 16px;
    border-radius: 16px;
    background: #fff;
    position: absolute;
    width: 100%;
    max-width: 420px;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    z-index: 999999;
    min-height: 100px;
  }
  .pp-window-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: #ececec;
    position: absolute;
    top: 16px;
    right: 16px;
    svg {
      width: 16px;
      height: 16px;
      color: #000;
    }
    cursor: pointer;
    &:hover {
      background: #dcdcdc;
    }
  }
  .pp-window-logo {
    margin-bottom: 24px;
    img {
      height: 32px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .pp-info-window {
    .pp-window-box {
      top: auto;
      bottom: 0;
      padding: 16px 24px;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      width: calc(100% - 48px);
      max-width: calc(100% - 48px);
    }
  }
}

.ant-message {
  z-index: 99999999;
}
