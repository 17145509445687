@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.admin-application {
  position: relative;
  .top-border {
    height: 1px;
    background: #ededed;
    position: absolute;
    left: 0;
    top: 45px;
    width: calc(100%);
  }
  > h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $dark-1000;
    svg {
      width: 28px;
      height: 28px;
      position: relative;
      top: -2px;
      margin-right: 8px;
    }
  }
  .ant-tabs-content-holder {
    #paypath-loading {
      margin-top: 180px;
    }
  }
  .app-tabs {
    margin-top: 16px;
  }
  .admin-search {
    gap: 6px;

    button {
      font-family: $font-title;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border-radius: 10px !important;
      svg {
        width: 14px;
        height: 14px;
        color: #757575 !important;
      }
    }
  }
  .manage-search {
    display: flex;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid #ececec;

    border-right: 1px solid #ececec;

    border-left: 1px solid #ececec;

    background: #fff;
    button {
      font-family: $font-title;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border-radius: 10px !important;
      svg {
        width: 14px;
        height: 14px;
        color: #757575 !important;
      }
    }
    .pp-search .ant-input-search-button {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.app-actions-drop-drop {
  .ant-dropdown-menu-title-content > span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: $font-title;
    font-weight: 400;
    gap: 6px;
    svg {
      width: 14px;
      height: 14px;
      color: #757575 !important;
    }
    &.bad {
      color: #cd1309;
      svg {
        color: #cd1309 !important;
      }
    }
  }
}
