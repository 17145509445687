@import "/src/_styleguide/variables.scss";

.payment-date {
  .due-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;

    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    margin: 0 0 8px 0;
  }
}
