@import "/src/_styleguide/variables.scss";

.pp-construction {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
  max-width: 600px;
  width: 100%;
  margin: 0 24px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 80px;
    height: 80px;
    position: relative;
    top: 8px;
  }
  .pp-construction-info {
    h2 {
      margin: 0 0 12px 0;
      color: #000;
      font-family: $font-title;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      margin: 0;
      color: #000;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      .pp-link {
        font-size: 16px !important;
        text-decoration: none !important;
      }
    }
  }
}
