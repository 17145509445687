@import "/src/~@centrate-io/barn/dist/variables";

.ab-menu {
  padding: 0 32px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  > .ant-menu-root {
    border-bottom: 0 !important;
  }
  .ant-menu > .ant-menu-item {
    padding: 0 12px !important;
    color: $dark-500;
    &:hover,
    &.ant-menu-item-selected {
      color: $dark-1000;
    }
  }
  .ant-menu-submenu-title,
  .ant-menu .ant-menu-submenu-title {
    color: $dark-500;
    &:hover {
      color: $dark-1000;
    }
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title,
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: $dark-1000;
  }
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    inset-inline: 12px !important;
  }
  .ant-menu-horizontal > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal > .ant-menu-item:hover::after,
  .ant-menu-horizontal > .ant-menu-item-selected:hover::after,
  .ant-menu-horizontal > .ant-menu-item-selected::after,
  .ant-menu-horizontal > .ant-menu-submenu-selected:hover::after,
  .ant-menu-horizontal > .ant-menu-submenu-selected::after,
  .ant-menu-horizontal > .ant-menu-submenu-active::after,
  .ant-menu-horizontal > .ant-menu-submenu-open::after {
    border-color: $dark-1000 !important;
  }
  .tab-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-item-selected,
  .ant-menu .ant-menu-item-selected,
  .ant-menu-item:hover {
    background-color: #a2d683;
  }

  .ant-menu-item-selected,
  .ant-menu .ant-menu-item-selected,
  .ant-menu-item:hover {
    color: #000;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    height: 32px !important;
    line-height: 32px !important;
  }
  .ant-menu-item .tab-name {
    .pp-tag {
      margin-left: 8px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $dark-1000;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      color: $dark-1000;
    }
  }
}
