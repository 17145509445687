@import "/src/_styleguide/variables.scss";

.ab-contacts {
  padding: 16px 32px;
  .ab-manage {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .csmall {
    b {
      color: #000;
      font-family: $font-main;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px; /* 150% */
      letter-spacing: -0.28px;
    }
    p {
      color: #757575;
      font-family: $font-main;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: -0.24px;
      margin: 2px 0 0 0;
      padding: 0;
    }
  }
}
