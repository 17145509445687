@import "/src/_styleguide/variables.scss";

.admin-wizard-review {
  .wzrd-cart {
    .cart-body {
      background: #fff;
      margin-bottom: 16px;
      > .cb-item {
        display: flex;
        padding: 12px 0px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-bottom: 1px solid #ececec;
        > div {
          color: #000;
          font-family: $font-title;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          flex: 1;
          &:last-child {
            text-align: right;
            font-weight: 400;
          }
          b {
            font-weight: 600;
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
      .plan-circle {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 8px;
        border-radius: 100px;
        background: #abebef;
        &.duration-18 {
          background: #b9abef;
        }
        &.duration-12 {
          background: #c5efab;
        }
        &.duration-custom {
          background: #dcdcdc;
        }
      }
    }
    .cart-info {
      display: flex;
      padding: 16px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid #83d1d6;
      background: #e7fbfc;
      color: #000;

      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      p {
        margin: 0;
      }
      svg {
        color: #5ab5bb;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        min-width: 24px;
      }
      b {
        font-weight: 500;
      }
    }
  }
}
