@import "/src/_styleguide/variables.scss";

.create-template-modal {
  .ct-mapping {
    .wb-headers {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ededed;
      background: #f0f4f8;
      > div {
        width: 30%;
        padding: 12px 16px;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &.ct-select {
          width: 40% !important;
        }
      }
    }
    .wb-actions {
      display: flex;
      align-items: center;
      border-top: 1px solid #ededed;
      padding: 16px 24px;
      justify-content: space-between;
    }
    .wb-work {
      max-height: 400px;
      overflow-y: scroll;
      .wb-map {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ededed;
        background: #fff;
        > div {
          width: 30%;
          max-width: 30%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 12px 16px;
          color: #000;
          font-family: $font-title;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          .ant-select-selection-placeholder,
          .ant-select-selection-item {
            font-size: 14px !important;
          }
          b {
            font-weight: 500;
          }
          &.ct-select {
            width: 40% !important;
            max-width: 40% !important;
          }
        }
      }
    }
  }
}
