@import "/src/_styleguide/variables.scss";

.pp-bar {
  .bar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 10px;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      color: #000;
    }
    &:hover {
      background: #d9d9d9;
    }
    &.active {
      background: #000;
      svg {
        color: #fff;
      }
    }
    &.on-call {
      background: #a2d683 !important;
      width: auto !important;
      padding: 0 8px;
      svg {
        color: #000 !important;
        margin-right: 6px;
      }
      .call-duration {
        color: #000;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
      }
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
      background: #fff !important;
      &:hover {
        background: #fff !important;
      }
      svg {
        color: #000;
      }
    }
  }
}
