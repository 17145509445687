@import "/src/_styleguide/variables.scss";

.snap-stat-bar {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
  .stat-card {
    border: 1px solid #eeedec;
    background: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    label {
      color: #757575;
      font-family: $font-title;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      margin: 0;
      text-transform: uppercase;
    }
    h3 {
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      margin: 0;
      span {
        font-family: $font-main;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 11px;
        margin-left: 12px;
        background: #a2d683;
        padding: 2px 5px;
        border-radius: 4px;
        color: #000;
        &.red {
          background: #f9d2ce;
        }
      }
    }
    &.processing {
      opacity: 0.55;
    }
  }
}
