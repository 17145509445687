@import "/src/_styleguide/variables.scss";

button.plaid-connect {
  margin: 8px 0;
  font-family: $font-title;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 8px;
  }
}
