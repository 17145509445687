@import "/src/_styleguide/variables.scss";

.wzrd-box.bank-info-box {
  .wzrd-body {
    padding: 24px 40px 40px 40px;
  }
  .bib-details {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid #a2d683;
    background: #fff;
    box-shadow: 0px 0px 0px 3px #e0f7d2;
    .bib-bank {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e8e3d8;
      width: 100px;
      height: 60px;
      background: #fff;
      box-shadow:
        0px 49px 14px 0px rgba(0, 0, 0, 0),
        0px 31px 12px 0px rgba(0, 0, 0, 0),
        0px 18px 11px 0px rgba(0, 0, 0, 0.01),
        0px 8px 8px 0px rgba(0, 0, 0, 0.02),
        0px 2px 4px 0px rgba(0, 0, 0, 0.02);
      img {
        height: 36px;
        width: 36px;
      }
    }
    .bib-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 6px;
      h5 {
        color: #000;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 100% */
        letter-spacing: -0.05px;
        margin: 0;
      }
      p {
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: -0.05px;
        margin: 0;
      }
    }
  }
  .bib-confirmed {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #e0f7d2;
    width: 100%;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 150% */
    svg {
      width: 20px;
      height: 20px;
      stroke-width: 3;
      color: #5b9d33;
    }
  }
}

// <div className="bib-details">
//           <Flex>
//             <img
//               src={`data:image/png;base64, ${institution?.logo}`}
//               alt={institution?.name}
//             />
//             <Flex vertical={true}>
//               <h5>{institution?.name}</h5>
//               <p>xxxxxxxx{metadata?.account?.mask}</p>
//             </Flex>
//           </Flex>
//           <div className="bib-confirmed">
//             <IconCheck />
//             Bank Connected
//           </div>
//         </div>
