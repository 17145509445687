@import "/src/_styleguide/variables.scss";

.create-template-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
  }
  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    margin: 0 !important;
    > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    button {
      font-weight: 500 !important;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      svg {
        stroke-width: 1.5;
        width: 20px;
        height: 20px;
      }
    }
  }
}
