@import "/src/~@centrate-io/barn/dist/variables";

.pp-input {
  &.pp-invalid {
    border-color: $error !important;
  }
}

.pp-input-wrapper {
  position: relative;
  > svg {
    position: absolute;
    left: 12px;
    z-index: 9;
    width: 24px;
    height: 24px;
    top: 15px;
  }
  &.pp-icon input {
    padding-left: 48px !important;
  }
  input.barn-input.pp-large {
    padding: 0 16px;
    height: 56px;
    line-height: 56px;
    border-radius: 12px;
  }
  input.barn-input.pp-medium {
    padding: 0 12px;
    height: 48px;
    line-height: 48px;
    border-radius: 10px;
  }
  input.barn-input.pp-tiny {
    padding: 0 8px;
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    font-size: 15px;
  }
  .ant-input-outlined:focus,
  .ant-input-outlined:focus-within {
    border-color: $light-0;
    box-shadow: none !important;
    outline: 0;
    background-color: #ffffff;
  }
  .ant-input-outlined:hover {
    border-color: $light-0;
    background-color: #ffffff;
  }
}
