@import "/src/_styleguide/variables.scss";

.edit-address-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
  }

  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0 !important;
    button {
      font-weight: 500 !important;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      justify-content: center;
    }
  }
  .break {
    widows: 100%;
    height: 1px;
    display: block;
    content: "";
    clear: both;
    background: #ececec;
    margin-left: -28px;
    margin-right: -28px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    > div {
      flex: 1;
    }
  }
  .pp-input-label > span {
    float: none !important;
    display: inline-flex;
    color: #78716c;
    margin-left: 4px;
  }
}
