@import "/src/_styleguide/variables.scss";

.texting-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
  }
  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0 !important;
    .text-compose {
      position: relative;
      width: 100%;
      textarea.pp-text-area {
        padding-right: 126px;
        padding-bottom: 64px;
      }
      button {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }
    }
  }
  /* Message window styling */
  .chat-window {
    max-height: 400px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    /* General bubble styling */
    .message {
      max-width: 70%;
      padding: 10px;
      margin: 14px 0 10px 0;
      border-radius: 15px;
      position: relative;
      font-size: 14px;
      line-height: 18px;
      z-index: 999;
      margin-top: 14px;
      > div {
        position: absolute;
        top: -15px;
        font-size: 12px;
        line-height: 12px;
        color: #757575;
      }
    }

    /* Left (grey) bubble */
    .message.left {
      background-color: #e0e0e0;
      margin-left: 10px;
      align-self: flex-start;
    }

    /* Right (blue) bubble */
    .message.right {
      background-color: #007aff;
      color: white;
      margin-right: 10px;
      align-self: flex-end;
      > div {
        right: 10px;
      }
    }

    /* Tail for left (grey) bubble */
    .message.right {
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: 0;
        right: -8px;
        height: 20px;
        width: 20px;
        background: #007aff;
        border-bottom-left-radius: 15px;
      }

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: -10px;
        width: 10px;
        height: 20px;
        background: white;
        border-bottom-left-radius: 10px;
      }
    }

    /* Tail for right (blue) bubble */
    .message.left {
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: -7px;
        height: 20px;
        width: 20px;
        background: #e0e0e0;
        border-bottom-right-radius: 15px;
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: -10px;
        width: 10px;
        height: 20px;
        background: white;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
