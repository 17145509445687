.select-user-filter {
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    > div {
      margin-right: 0;
    }
  }
}
