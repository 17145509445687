@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.admin-application-texts {
  padding: 24px 32px 100px 32px;
  height: calc(100% - 56px - 92px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  /* General bubble styling */
  .no-text {
    color: #777;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
  }
  .text-compose {
    width: calc(100% - 240px);
    position: absolute;
    bottom: 92px;
    max-width: calc(1024px - 64px);
    textarea.pp-text-area {
      padding-right: 76px;
      box-shadow:
        0 0 2px rgba(0, 0, 0, 0.12),
        0 2px 4px rgba(0, 0, 0, 0.14);
    }
    button {
      position: absolute;
      right: 8px;
      top: 7px;
    }
  }
  .message {
    max-width: 70%;
    padding: 10px;
    margin: 14px 0 10px 0;
    border-radius: 15px;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    z-index: 9;
    margin-top: 14px;
    > div {
      position: absolute;
      top: -15px;
      font-size: 12px;
      line-height: 12px;
      color: #757575;
      white-space: nowrap;
      overflow: visible;
    }
  }

  /* Left (grey) bubble */
  .message.left {
    background-color: #e0e0e0;
    margin-left: 10px;
    align-self: flex-start;
  }

  /* Right (blue) bubble */
  .message.right {
    background-color: #007aff;
    color: white;
    margin-right: 10px;
    align-self: flex-end;
    > div {
      right: 10px;
    }
  }

  /* Tail for left (grey) bubble */
  .message.right {
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      right: -8px;
      height: 20px;
      width: 20px;
      background: #007aff;
      border-bottom-left-radius: 15px;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-left-radius: 10px;
    }
  }

  /* Tail for right (blue) bubble */
  .message.left {
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: -7px;
      height: 20px;
      width: 20px;
      background: #e0e0e0;
      border-bottom-right-radius: 15px;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-right-radius: 10px;
    }
  }
}
