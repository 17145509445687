.pp-checkbox {
  .ant-checkbox-inner {
    outline: 0 !important;
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #090e15;
  }
  &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #090e15 !important;
    border-color: #090e15 !important;
  }

  &.pp-checkbox-large {
    .ant-checkbox .ant-checkbox-inner {
      width: 24px;
      height: 24px;
    }
    .ant-checkbox .ant-checkbox-inner:after {
      width: 5px;
      height: 11px;
      inset-inline-start: 27%;
    }
  }
}
