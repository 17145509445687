@import "/src/_styleguide/variables.scss";

.admin-custom-plan {
  .plan-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    h5 {
      margin: 0;
      color: #000;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 112.5% */
      letter-spacing: 0.16px;
    }
    svg {
      width: 16px;
      height: 16px;
      color: #9d9d9d;
    }
  }
  .plan-rate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: #000;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    letter-spacing: 0.16px;
    margin-top: 8px;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      margin-left: -6px;
    }
    font-weight: 500;
    &.no-svg {
      svg {
        opacity: 0;
      }
      b {
        font-weight: 400 !important;
      }
      font-weight: 400;
    }
  }
  &.collapsed {
    .wzrd-body {
      display: none;
    }
    .wzrd-header {
      border-bottom: 0 !important;
    }
  }
  .wzrd-header.custom h3 > span {
    color: #9d9d9d;
    font-family: $font-title;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-left: 8px;
  }
  .info-box {
    padding: 16px 12px;
    border-radius: 12px;
    border: 1px solid #83d1d6;
    background: #e8f6f7;
    display: flex;
    align-items: flex-start;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-top: 16px;
    p {
      margin: 0;
    }
    svg {
      color: #5ab5bb;
      width: 20px;
      height: 20px;
      margin-right: 12px;
      min-width: 20px;
    }
    b {
      font-weight: 500;
    }
  }
}
