@import "/src/_styleguide/variables.scss";

.pp-subtitle {
  color: #000;
  text-align: center;
  font-family: $font-title;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 16px 0;
  text-align: left;
}

@media (max-width: $breakpoint-tablet) {
  .pp-subtitle {
    font-size: 18px;
    margin: 0 0 12px 0;
    text-align: center;
  }
}

@media (max-width: $breakpoint-mobile) {
  .pp-subtitle {
    font-size: 16px;
    margin: 0 0 12px 0;
    text-align: center;
  }
}
