@import "/src/_styleguide/variables.scss";

.stat-bar {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
  .stat-card {
    border: 1px solid #eeedec;
    background: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    label {
      color: #757575;
      font-family: $font-title;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      margin: 0;
      text-transform: uppercase;
    }
    h3 {
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      margin: 0;
    }
  }
}
