@import "/src/_styleguide/variables.scss";

.admin-application-accounts {
  margin: 24px 32px;
  .admin-information-boxes {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}
