@import "/src/_styleguide/variables.scss";

.standard-import-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
  }
  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 16px 0 0 0 !important;
    button {
      font-weight: 500 !important;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      text-align: center;
      justify-content: center;
    }
  }
  .import-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    p,
    h3 {
      margin: 0;
    }
    p > b {
      font-weight: 600 !important;
    }
    button {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
