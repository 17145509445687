@import "/src/_styleguide/variables.scss";

.learn-button {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;

    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    pointer-events: none;
    img {
      margin-left: 8px;
      height: 22px;
    }
  }
}

.credit-confirmation {
  border-radius: 8px;
  background: #f8f6f2;
  padding: 12px;
  margin-top: 0px;
  color: #000;
  font-family: $font-main;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.step-credit {
  .bottom-actions {
    margin-top: 20px;
  }
  > h2 {
    margin-bottom: 0;
  }
  .experian-button {
    display: flex;
    justify-content: center;
    margin: 32px 0;
    color: #000;

    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    img {
      margin-left: 8px;
      height: 22px;
    }
  }
}
