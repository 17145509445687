@import "/src/_styleguide/variables.scss";

.pp-dialpad {
  border-top: 1px dotted #cfcfcf;
  padding-top: 16px;
  margin-top: 12px;
  .keypad {
    text-align: center;
    color: #000;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .ant-flex {
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 4px;
    > div {
      user-select: none;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 54px;
      border-radius: 12px;
      cursor: pointer;
      color: #000;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      &:hover {
        background: #eeedec;
      }
    }
  }
}
