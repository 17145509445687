@import "/src/_styleguide/variables.scss";

.pp-dialer {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 50px 16px 20px 16px;
  box-shadow:
    0px 58px 16px 0px rgba(0, 0, 0, 0),
    0px 37px 15px 0px rgba(0, 0, 0, 0.01),
    0px 21px 13px 0px rgba(0, 0, 0, 0.02),
    0px 9px 9px 0px rgba(0, 0, 0, 0.04),
    0px 2px 5px 0px rgba(0, 0, 0, 0.04);
  bottom: 64px;
  right: calc((100% - 240px) / 2);
  transform: translateX(50%);
  transition:
    right 0.2s,
    bottom 0.3s linear,
    opacity 0.2s ease-in-out,
    padding 0.3s ease-in-out,
    max-width 0.3s ease-in-out,
    max-width 0.3s ease-in-out,
    width 0.3s ease-in-out,
    min-width 0.3s ease-in-out,
    height 0.3s ease-in-out;
  overflow: hidden;
  opacity: 1;
  height: 233px;
  &.minimizing {
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    bottom: 40px !important;
    opacity: 0.2 !important;
  }
  &.minimize {
    display: none !important;
  }
  &.script {
    width: 440px;
    min-width: 440px;
    max-width: 440px;
    height: 553px;
  }
  &.dial-pad {
    height: 526px;
  }
  .dialer-close {
    display: flex;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
      background: #d9d9d9;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .dialer-finished {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dotted #cfcfcf;
    padding-top: 20px;
    margin-top: 16px;
  }
  .dialer-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px dotted #cfcfcf;
    padding-top: 12px;
    margin-top: 32px;
    > .dialer-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      width: 40px;
      height: 32px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
      }
      &:hover {
        background: #d9d9d9;
      }
      &.active {
        background: #000;
        svg {
          color: #fff;
        }
      }
      &.ready {
        background: #a2d683 !important;
        &:hover {
          background: #92c673 !important;
        }
      }
      &.in-progress {
        color: #fff !important;
        background: #db313b !important;
        &:hover {
          background: #db313b !important;
        }
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
        background: #fff !important;
        &:hover {
          background: #fff !important;
        }
      }
      &.call-btn.disabled {
        background: #d9d9d9 !important;
        &:hover {
          background: #d9d9d9 !important;
        }
      }
    }
  }
  .dialer-info {
    width: 100%;
    .dialer-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      h3 {
        display: inline-block;
        color: #000;
        font-family: $font-title;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        margin: 0 8px 0 0;
        text-align: left;
      }
      .pp-tag {
        position: relative;
        top: -1px;
      }
    }
    .timer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      color: #000;
      font-family: $font-title;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 6px;
        svg {
          width: 22px;
          height: 22px;
          stroke-width: 1.5px;
          position: relative;
          top: -1px;
        }
        > span {
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #d9d9d9;
          &.in-progress,
          &.completed {
            background: #a2d683 !important;
          }
        }
        &.timing {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-top: 12px;
        }
      }
    }
  }
}

.site-layout.collapsed .pp-dialer {
  right: calc((100% - 80px) / 2);
}
