@import "/src/_styleguide/variables.scss";

.exp-drawer {
  .menu {
    padding: 24px 20px 24px 32px;
    width: 200px;
    position: absolute;
    top: 86px;
    left: 0;
    height: calc(100% - 86px - 48px);
    > h4 {
      font-family: $font-title;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.55px;
      text-transform: uppercase;
      padding: 12px 12px 0 12px;
      margin: 0 0 6px 0;
      color: #12969e;
      &.applicant {
        color: #ba2f7d;
        padding-top: 0;
      }
    }
    > p {
      color: #757575;
      font-family: $font-title;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
    > .sep {
      height: 1px;
      width: 100%;
      content: "";
      clear: both;
      margin: 12px 0;
      background: #ededed;
    }
    > .cr-item {
      display: flex;
      padding: 12px;
      align-items: center;
      border-radius: 10px;
      background: transparent;
      user-select: none;
      cursor: pointer;
      gap: 12px;
      margin-bottom: 2px;
      position: relative;
      > span {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        background: #fff;
        color: #000;
        text-align: center;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
      > h5 {
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        flex: 1;
      }
      &:hover {
        background: #ececec;
      }
      &.selected {
        background: #ececec;
      }
      &.completed {
        > span {
          background: #000;
          border-color: #000;
          svg {
            color: #fff;
          }
        }
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }
}
