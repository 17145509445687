.invite-user-modal {
  .ant-modal-content {
    background: #22272f;
    border-radius: 5px;
    overflow: hidden;
  }
  .ant-input-number-group-wrapper {
    width: 100%;
  }
  .ant-picker {
    width: 100%;
  }
  .auth-actions {
    margin-top: 32px;
  }
  h2 {
    margin-bottom: 24px;
    color: #fff;
  }
  .ant-form-item-label label {
    color: #fff;
  }
  .ant-modal-close-x svg {
    color: #fff;
  }
  .invite-form > div {
    margin-bottom: 16px;
  }
}
