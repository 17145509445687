@import "/src/_styleguide/variables.scss";

.my-task-card {
  padding: 20px 0px;
  flex: 1;
  width: 100%;
  border-bottom: 1px solid #ececec;
  position: relative;
  .tc-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
    .tc-header {
      display: flex;
      gap: 10px;
      align-items: center;
      h4 {
        margin: 0 !important;
        color: #000;
        font-family: $font-title;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 146.667% */
        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
      button {
        padding: 3px 3px !important;
        height: 18px !important;
        border-radius: 6px !important;
        svg {
          width: 12px;
          height: 12px;
          color: #000;
        }
      }
    }
    > p {
      margin: 0 !important;
      color: #737272;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      b {
        font-weight: 500;
        color: #434242;
      }
    }
    .tc-info {
      display: flex;
      gap: 4px;
      align-items: center;
      .pp-tag {
        margin-inline-end: 0 !important;
      }
    }
  }
  &.done {
    .tc-header h4 {
      text-decoration: line-through;
      color: #757575;
    }
  }
  .tc-customer > span {
    cursor: pointer;
  }
  .pp-tag {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #000;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    svg {
      width: 14px;
      height: 14px;
      color: #9d9d9d;
    }
  }
  button.tc-complete {
    display: none;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  &:hover {
    border-radius: 10px;
    background: #f3f3f3;
    margin: 0 -16px;
    padding-left: 16px;
    padding-right: 16px;
    button.tc-complete {
      display: flex;
    }
  }
}
