@import "/src/_styleguide/variables.scss";

.pp-yesno {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  align-self: stretch;
  > div {
    border-radius: 12px;
    border: 1px solid #d7d7d7;
    display: flex;
    height: 50px;
    min-height: 50px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: #000;

    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    &:hover {
      background: #ececec;
    }
    &.pp-selected {
      background: #a2d683 !important;
    }
    &.pp-yes.pp-selected {
      font-weight: 500;
    }
  }
  .pp-checked {
    display: flex;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 12px;
    justify-content: center;
    align-items: center;
    background: #000;
    border-radius: 50%;
    svg {
      color: #fff;
      width: 15px;
      height: 15px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .pp-yesno {
  }
}

@media (max-width: $breakpoint-mobile) {
  .pp-yesno {
  }
}
