@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.payment-data-modal {
  .barn-modal-close {
    display: none !important;
  }

  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-header {
    display: flex;
    padding: 20px 12px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #ececec;
    color: #000;
    font-family: $font-major;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    > span {
      color: #757575;
      font-weight: 400;
    }
  }
  .barn-modal-actions {
    padding: 0 16px 12px 16px;
    margin-top: 0 !important;
  }
  .barn-modal-body {
    margin-top: 0 !important;
    padding: 16px 28px;
  }
  .no-history {
    margin: 24px 0;
    text-align: center;
  }
  .history-list > .history {
    display: flex;
    align-items: flex-start;
    position: relative;
    gap: 8px;
    padding: 12px 0;
    .history-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      background: #c5efab;
      border: 1px solid #e0e0e0;
      background: #fff;
      svg {
        width: 16px;
        height: 16px;
      }
      &.refunded,
      &.processed {
        background: #c5efab;
        border-color: #c5efab;
      }
      &.voided {
        background: #e0e0e0;
        border-color: #e0e0e0;
      }
      &.failed,
      &.refund-failed {
        background: #f9d2ce;
        border-color: #f9d2ce;
      }
      &.expired {
        background: #facd8a;
        border-color: #facd8a;
      }
      &.paused,
      &.refund-pending {
        background: #fcefbf;
        border-color: #fcefbf;
      }
    }
    .history-info {
      flex: 1;
      h4 {
        display: flex;
        margin: 0;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-family: $font-major;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        font-weight: 400;
        b {
          font-weight: 600;
        }
      }
      p {
        margin: 0;
        color: #757575;
        font-family: $font-major;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 21px;
      }
    }
  }
}
