@import "/src/_styleguide/variables.scss";

.pp-plan-card {
  border-radius: 0;
  border-bottom: 1px solid #ececec;
  padding: 24px 0;
  background: #fff;
  position: relative;
  overflow: hidden;

  .oc-info,
  .oc-top {
    display: flex;
    align-items: center;
    button {
      width: 112px;
      > span {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }
  }
  .oc-content {
    flex: 1;
    h4 {
      color: #000;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
    p {
      margin: 8px 0 0 0;
      color: #757575;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .oc-content p.notice {
    margin: 8px 0 0 0;
    color: #949494;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-decoration: none;
  }
  .oc-circle {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: #abebef;
    margin-right: 12px;
    svg {
      width: 16px;
      height: 16px;
      color: #000;
    }
  }
  &.duration-18 .oc-circle {
    background: #b9abef;
  }
  &.duration-12 .oc-circle {
    background: #c5efab;
  }
}
