@import "/src/_styleguide/variables.scss";
@import "react-credit-cards-2/dist/es/styles-compiled.css";

.credit-card-modal {
  .ant-modal-content {
    background: #22272f;
    border-radius: 5px;
    overflow: hidden;
  }
  .ant-input-number-group-wrapper {
    width: 100%;
  }
  .ant-picker {
    width: 100%;
  }
  .auth-actions {
    margin-top: 32px;
  }
  h2 {
    margin-bottom: 24px;
    color: #fff;
  }
  .ant-form-item-label label {
    color: #fff;
  }
  .ant-modal-close-x svg {
    color: #fff;
  }
  .ar-form {
    border-radius: 16px;
    border: 1px solid #cfcfcf;
    background: #fff;
    box-shadow: 0px 0px 0px 4px #f3f3f3;
    .ar-title {
      display: flex;
      padding: 10px 16px 8px 16px;
      align-items: flex-start;
      align-self: stretch;
      background: #f3f3f3;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .ar-inputs {
      display: flex;
      padding: 24px 32px 32px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      .rccs {
        margin-bottom: 12px;
      }
      > div {
        width: 100%;
      }
      .cc-exp {
        flex: 1;
      }
      .cc-cvc {
        width: 120px;
      }
    }
  }
  .has-bank {
    height: 24px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 6px;
    color: #000;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.05px;
    svg {
      width: 16px;
      height: 16px;
      color: #7ec652;
    }
  }
  .barn-modal-actions {
    padding: 0 0 24px 0;
  }
  .ant-modal-body {
    padding: 40px 40px 0 40px !important;
  }
  .connect-title {
    color: #000;

    text-align: center;
    font-family: $font-title;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 35px;
    b {
      font-weight: 500;
    }
  }
  @keyframes loaderspin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .routing-box {
    position: relative;
    .routing-icon {
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      border-radius: 9px;
      background: #ececec;
      border: 1px solid #ececec;
      svg {
        width: 20px;
        height: 20px;
        color: #000;
        &.tabler-icon-loader {
          animation: loaderspin 2s infinite linear;
        }
      }
      img {
        width: 20px;
        height: 20px;
      }
      position: absolute;
      bottom: 7px;
      left: 8px;
      z-index: 999;
      &.has-logo {
        border: 1px solid #d6d6d6;
        background: #fff;
        box-shadow:
          0px 5.714px 3.429px 0px rgba(0, 0, 0, 0.03),
          0px 2.286px 2.286px 0px rgba(0, 0, 0, 0.04),
          0px 1.143px 1.143px 0px rgba(0, 0, 0, 0.05);
      }
    }
    .route-input {
      position: relative;
    }
    input {
      padding-left: 48px !important;
    }
    &.no-bank {
      input {
        box-shadow: 0 0 0 2px #fcefbf !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        &:hover,
        &:focus {
          border-color: transparent !important;
        }
      }
    }
  }
  .account-type {
    .ant-radio-wrapper .ant-radio-checked::after {
      border: 1px solid #000 !important;
    }
    .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
      border-color: #000;
      background-color: #000;
    }
    .ant-radio-wrapper:hover .ant-radio-wrapper,
    .ant-radio-wrapper:hover .ant-radio-inner {
      border-color: #000;
    }
    .ant-radio-wrapper {
      padding: 10px 12px;
      border-radius: 10px;
      border: 1px solid #ececec;
      margin-bottom: 8px;
      &.ant-radio-wrapper-checked,
      &:hover {
        border: 1px solid #000 !important;
      }
    }
  }
  .no-bank-found {
    align-items: flex-start;
    gap: 10px;
    padding: 8px 10px;
    border-radius: 0px 0px 12px 12px;
    border-right: 1px solid #fcefbf;
    border-bottom: 1px solid #fcefbf;
    border-left: 1px solid #fcefbf;
    background: #fcefbf;
    margin-left: -2px;
    margin-right: -2px;
    .nbf-details {
      display: flex;
      flex-direction: column;
      gap: 0;
      h4 {
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        margin: 0;
      }
      p {
        color: #000;
        font-family: $font-title;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.5px;
        margin: 0;
      }
    }
  }
  .no-match {
    color: #db313b;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.05px;
    margin-top: 6px;
  }
  .connect-icons {
    width: 100%;
    height: 60px;
    position: relative;
    > div {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      border-radius: 80px;
      border: 2px solid #fff;
      background: #bf4956;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 36px;
        height: 36px;
      }
      &.customer {
        background: #000;
        img {
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50px, -50%);
      }
      &.vendor {
        background: #000;
        position: absolute;
        img {
          -webkit-filter: invert(100%);
          filter: invert(100%);
          width: 32px;
          height: 32px;
        }
        left: 50%;
        top: 50%;
        transform: translate(-6px, -50%);
      }
    }
  }
}
