@import "/src/_styleguide/variables.scss";

.my-tasks-drawer {
  border-radius: 16px;
  overflow: hidden;
  .ant-drawer-header {
    display: none !important;
  }
  .ant-drawer-body {
    padding: 0;
    position: relative;
    overflow: hidden;
    .new-task-title {
      display: flex;
      padding: 16px 24px 16px 24px;
      width: calc(100% - 48px);
      align-items: center;
      background: #fff;
      gap: 8px;
      align-self: stretch;
      border-bottom: 1px solid #ececec;
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      position: absolute;
      top: 0;
      left: 0;
      > .tc-close {
        position: absolute;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        background: #fff;
        box-shadow: 1px 1px 0px 0px #f3f3f3;
        svg {
          width: 16px;
          height: 16px;
          color: #090e15;
        }
        cursor: pointer;
        &:hover {
          background: #fafaf9;
        }
      }
    }
    .at-tabs {
      position: absolute;
      top: 60px;
      display: flex;
      align-items: center;
      gap: 2px;
      background: #fafaf9;
      padding: 10px 16px;
      width: calc(100% - 32px);
      .tc-count {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 16px;
        height: 16px;
        color: #000;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 6px;
        background: #ececec;
        position: relative;
        top: 1px;
        border: 1px solid #ececec;
        &.todo {
          border: 1px solid #f2a8dd;
          background: #fce4f6;
          color: #96076e;
        }
      }
      > div {
        display: flex;
        height: 32px;
        padding: 0 12px;
        align-items: center;
        gap: 6px;
        border: 1px solid transparent;
        cursor: pointer;
        > svg {
          width: 16px;
          height: 16px;
          color: #8f8f8f;
        }
        &.at-active,
        &:hover {
          border-radius: 10px;
          border: 1px solid #cfcfcf;
          background: #fff;
          > svg {
            color: #000;
          }
        }
      }
    }
    .my-tasks-body {
      height: calc(100% - 60px - 54px);
      margin-top: 114px;
      overflow-y: auto;
      .at-list {
        display: flex;
        padding: 4px 24px 16px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--sds-size-space-300);
        align-self: stretch;
        > div:last-child {
          border-bottom: 0 !important;
        }
      }
    }
  }
}

.ppsd-task .ant-select-item-option-content {
  display: flex;
  align-items: center;
  color: #000;
  .pp-task-badge {
    margin-right: 8px;
  }
}

.my-tasks-parent {
  z-index: 999 !important;
  .ant-drawer-content-wrapper {
    border-radius: 16px;
    height: calc(100% - 24px);
    top: 12px;
    left: 252px;
    border: 1px solid #dedddc !important;
    box-shadow:
      334px 0px 94px 0px rgba(0, 0, 0, 0),
      214px 0px 86px 0px rgba(0, 0, 0, 0.01),
      120px 0px 72px 0px rgba(0, 0, 0, 0.02),
      53px 0px 53px 0px rgba(0, 0, 0, 0.04),
      13px 0px 29px 0px rgba(0, 0, 0, 0.05) !important;
  }
  .ant-drawer-mask {
    background: rgba(0, 0, 0, 0.05);
  }
  &.collapsed {
    .ant-drawer-content-wrapper {
      left: 92px !important;
    }
  }
}

.my-tasks-parent.ant-drawer-open {
  z-index: 999 !important;
}
