@import "/src/_styleguide/variables.scss";

.sab-search {
  height: 40px;
  .ant-input-group-addon {
    display: none !important;
  }
  input {
    border-radius: 6px !important;
    height: 40px;
  }
}

.sab-drop {
  .sab-option {
    font-weight: 600;
    > span {
      font-weight: 400;
      color: #757575;
      margin-left: 8px;
    }
  }
}
