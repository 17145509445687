@import "/src/_styleguide/variables.scss";

.pp-email {
  width: 720px;
  max-width: 720px;
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 50px 0 0 0;
  box-shadow:
    0px 58px 16px 0px rgba(0, 0, 0, 0),
    0px 37px 15px 0px rgba(0, 0, 0, 0.01),
    0px 21px 13px 0px rgba(0, 0, 0, 0.02),
    0px 9px 9px 0px rgba(0, 0, 0, 0.04),
    0px 2px 5px 0px rgba(0, 0, 0, 0.04);
  bottom: 64px;
  right: calc((100% - 240px) / 2);
  transform: translateX(50%);
  transition:
    right 0.2s,
    bottom 0.3s linear,
    opacity 0.3s ease-in-out,
    padding 0.3s ease-in-out,
    max-width 0.3s ease-in-out,
    width 0.3s ease-in-out,
    min-width 0.3s ease-in-out,
    height 0.3s ease-in-out;
  overflow: hidden;
  height: 544px;
  opacity: 1;
  &.minimizing {
    width: 0px !important;
    min-width: 0px !important;
    max-width: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    bottom: 40px !important;
    opacity: 0.2 !important;
  }
  &.minimize {
    display: none !important;
  }
  .pp-templates {
    width: 240px;
  }
  .pp-select.ant-select .ant-select-selector > span {
    font-size: 14px;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }
  .texting-info {
    width: 100%;
    padding: 16px 0 12px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h3 {
      display: inline-block;
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      margin: 0 8px 0 0;
      text-align: left;
    }
  }
  .email-window {
    display: flex;
    flex-direction: column;
    padding: 0 0;
    width: 100%;
    input {
      font-size: 14px;
    }
  }
  .to-box {
    border-bottom: 1px solid #ececec;
    padding: 4px 0;
  }
  .email-to {
    align-items: center;
    gap: 8px;
    padding: 0px 12px 0px 12px;
    .ant-select-selector {
      border: 0 !important;
      outline: 0 !important;
      box-shadow: none !important;
    }
    .pp-select.pp-medium,
    .pp-select.pp-medium .ant-select-selector {
      height: auto !important;
    }
    .ant-select-arrow {
      display: none !important;
    }
    label {
      margin: 0;
      width: 40px;
    }

    .email-from {
      border: 0 !important;
      outline: 0 !important;
      box-shadow: none !important;
      color: #000;
      height: 24px !important;
      line-height: 24px !important;
      display: flex;
      align-self: center;
      flex: none;
      box-sizing: border-box;
      max-width: 100%;
      margin-block: 2px;
      border-radius: 4px;
      cursor: default;
      transition:
        font-size 0.3s,
        line-height 0.3s,
        height 0.3s;
      margin-inline-end: 4px;
      padding-inline-start: 8px;
      padding-inline-end: 8px;
      background: rgba(0, 0, 0, 0.06);
      border: 1px solid transparent;
    }
  }
  .email-body {
    border-bottom: 1px solid #ececec;
    .ql-toolbar,
    .ql-container {
      border: 0 !important;
      border-radius: 0 !important;
    }
  }
  .email-subject {
    > div {
      width: 100%;
      input {
        border: 0 !important;
        outline: 0 !important;
        box-shadow: none !important;
        color: #000;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
      }
    }
    .ant-select-selector {
      border: 0 !important;
      outline: 0 !important;
      box-shadow: none !important;
    }
    border-bottom: 1px solid #ececec;
  }
  .email-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  .dialer-close {
    display: flex;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    z-index: 999;
    background: #fff;
    &:hover {
      background: #d9d9d9;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.site-layout.collapsed .pp-texting {
  right: calc((100% - 80px) / 2);
}

.ppsd-task .ant-select-item-option-content {
  display: flex;
  align-items: center;
  color: #000;
  .pp-task-badge {
    margin-right: 8px;
  }
}
