.customer-tabs {
  position: relative;
  top: -25px;
  .tab-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      color: #9d9d9d;
    }
    &.success svg {
      color: #a2d683;
    }
    &.warning svg {
      color: #eeaf0d;
    }
    &.danger svg {
      color: #db313b;
    }
  }
  .ant-tabs.active .ant-tabs-content-holder,
  .ant-tabs.paused .ant-tabs-content-holder,
  .ant-tabs.cancelled .ant-tabs-content-holder,
  .ant-tabs.modifying .ant-tabs-content-holder {
    position: absolute;
    top: 46px;
    height: calc(100vh - 106px);
    width: calc(100vw - 240px);
    overflow-y: scroll;
    margin-left: -32px;
    margin-right: -32px;
    padding: 32px;
  }
  .admin-toggle {
    display: inline-block;
    b {
      font-weight: 500;
    }
    button {
      margin-left: 8px;
    }
    margin-right: 16px;
  }
}
