@import "/src/_styleguide/variables.scss";

.exp-drawer {
  .header {
    position: absolute;
    top: 0;
    left: 0;
    height: 46px;
    width: calc(100% - 40px);
    border-bottom: 1px solid #ececec;
    padding: 24px 16px 16px 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    z-index: 9;
    > img {
      width: 24px;
      height: 24px;
      background: #fff;
      border-radius: 20px;
      border: 1px solid #cfcfcf;
      box-shadow: 0px 0px 0px 2px #f3f3f3;
      padding: 8px;
    }
    .report-details {
      flex: 1;
      h2 {
        color: #000;
        font-family: $font-title;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 4px 0;
      }
      p {
        color: #757575;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
      }
    }
    .close-drawer {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 70px;
      background: #ececec;
      cursor: pointer;
      &:hover {
        background: #dcdcdc;
      }
    }
  }
}
