@import "/src/_styleguide/variables.scss";

.step-register {
  > h2 {
    margin: 0;
  }
  p.final-info {
    margin: 16px 0 56px 0;
    color: #000 !important;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    b {
      font-weight: 500;
    }
  }
  .line-item {
    .due-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #000;

      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin: 0 0 8px 0;
    }
  }
  .final-actions {
    flex: 1;
    .due-amount {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      > div {
        color: #000;
        font-family: $font-title;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }
  .final-trust {
    display: flex;
    align-items: flex-start;
    color: #000;
    font-family: $font-main;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.05px;
    p {
      margin: 0;
    }
    svg {
      color: #000;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      min-width: 18px;
    }
    b {
      font-weight: 700;
    }
  }
  .info-box {
    padding: 16px 12px;
    border-radius: 12px;
    border: 1px solid #83d1d6;
    background: #e8f6f7;
    display: flex;
    align-items: flex-start;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-top: 16px;
    p {
      margin: 0;
    }
    svg {
      color: #5ab5bb;
      width: 20px;
      height: 20px;
      margin-right: 12px;
      min-width: 20px;
    }
    b {
      font-weight: 500;
    }
  }
  p.final-terms {
    color: #000;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.18px;
    b {
      font-weight: 500;
    }
    a {
      font-weight: 500;
      color: #000;
    }
  }
  .bottom-actions {
    margin-top: 40px;
  }
  button.confirm-desktop {
    display: flex;
  }
  button.confirm-mobile {
    display: none;
  }
  &.step-review {
    .final-info {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .step-register {
    .pp-avatar {
      display: none;
    }
    .bottom-actions {
      margin-top: 0;
      .final-actions {
        width: 100%;
      }
      .final-terms {
        display: none;
      }
    }
    button.confirm-desktop {
      display: none;
    }
    button.confirm-mobile {
      display: flex;
    }
  }
}
