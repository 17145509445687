@import "/src/_styleguide/variables.scss";

.wzrd-dates {
  .ant-picker .ant-picker-input > input {
    font-size: 16px;
  }
}

.admin-wizard-plan {
  .plan-radio {
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    border: 6px solid #ececec;
    background: #ececec;
    &.selected {
      border-color: #000;
    }
  }
  .plan-cards > div:first-child {
    padding-top: 0;
  }
  .plan-cards > div:last-child {
    border-bottom: 0;
  }
  .plan-includes,
  .plan-custom {
    h5 {
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.14px;
      margin: 0 0 12px 0;
    }
    p {
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.14px;
      margin: 0;
    }
    .bullets {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      > .bullet {
        display: flex;
        align-items: center;
        flex: 0 0 50%;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
        text-transform: capitalize;
        margin-bottom: 6px;
        svg {
          color: #9d9d9d;
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }
      }
    }
  }
  .plan-custom {
    margin-bottom: 24px;
  }
}

.general-info-box {
  padding: 16px 12px;
  border-radius: 12px;
  border: 1px solid #83d1d6;
  background: #e8f6f7;
  display: flex;
  align-items: flex-start;
  color: #000;
  font-family: $font-title;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 16px;
  p {
    margin: 0;
  }
  svg {
    color: #5ab5bb;
    width: 20px;
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
  }
  b {
    font-weight: 500;
  }
}
