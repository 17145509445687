@import "/src/_styleguide/variables.scss";

.change-role-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
  }
  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0 !important;
    button {
      font-weight: 500 !important;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }
  }
  .barn-modal-close {
    display: flex;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 42px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 1px 1px 0px 0px #f3f3f3;
    &:hover {
      border-color: #000;
    }
  }
  .role-options {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .ro-option {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 16px;
      flex: calc(50% - 44px);
      max-width: calc(50% - 44px);
      border-radius: 12px;
      border: 1px solid #ececec;
      background: #fff;
      height: 120px;
      overflow: hidden;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      svg {
        display: none;
      }
      p {
        margin: 0 !important;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
      &:hover {
        border-radius: 12px;
        border: 1px solid #ececec;
        background: #ececec;
      }
      &.selected {
        border: 1px solid #000;
        background: #fff;
        box-shadow: 0px 0px 0px 3px #ececec;
        svg {
          display: block;
          position: absolute;
          top: 16px;
          right: 16px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .role-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    .rc-icon {
      display: flex;
      height: 40px;
      width: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      border: 1px solid #b9b9b9;
      background: #bcbcb8;
      margin-bottom: 8px;
      svg {
        color: #eeeeee;
      }
    }
    h3 {
      margin: 0;
      text-align: center;
      color: #000;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }
    p {
      margin: 0;
      text-align: center;
      color: #757575;
      font-family: $font-title;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }
  }
}
