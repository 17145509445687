@import "/src/_styleguide/variables.scss";

.admin-wizard-menu {
  display: flex;
  flex-direction: column;
  gap: 2px;
  .awm-item {
    display: flex;
    padding: 12px;
    align-items: center;
    border-radius: 10px;
    background: transparent;
    user-select: none;
    cursor: pointer;
    gap: 12px;
    > span {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d9d9d9;
      background: #fff;
      svg {
        width: 14px;
        height: 14px;
        color: #000;
        stroke-width: 2px;
      }
    }
    > h5 {
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
    &:hover {
      background: #ececec;
    }
    &.selected {
      background: #ececec;
    }
    &.completed {
      > span {
        background: #000;
        border-color: #000;
        svg {
          color: #fff;
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
  .link-disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  .awm-sep {
    width: 100%;
    height: 1px;
    margin: 8px 0;
    display: block;
    content: "";
    clear: both;
    background: #ececec;
  }
}
