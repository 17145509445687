@import "/src/_styleguide/variables.scss";

.pp-experian {
  h2 {
    margin-bottom: 0 !important;
    margin-top: 56px;
  }
  .pp-experian-logo {
    text-align: center;
    margin-top: 32px;
    img {
      height: 36px;
    }
  }
}

.app-loading {
  > .spinner {
    text-align: center;
    padding: 56px 0;
    svg {
      color: #a2d683;
      width: 2em !important;
    }
  }
  > p {
    text-align: center;
    color: #000;

    text-align: center;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}

@media (max-width: $breakpoint-tablet) {
  .app-loading > .spinner {
    padding: 40px 0;
  }
}

@media (max-width: $breakpoint-mobile) {
  .app-loading > .spinner {
    padding: 32px 0;
  }
}
