@import "/src/_styleguide/variables.scss";
@import "/src/~@centrate-io/barn/dist/variables";

.pp-time-range {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .ant-select {
    width: 120px;
  }
  .ant-calendar-picker {
    width: 200px;
    margin: 0;
  }
  > span {
    font-family: $font-title;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #090e15;
    margin: 0 12px;
    &.no-left {
      margin-left: 0;
    }
  }
  .ant-select {
    height: 36px;
    &:hover .ant-select-selector,
    &.ant-select-focused .ant-select-selector {
      border-color: $dark-1000 !important;
      box-shadow: none !important;
    }
  }
  .ant-picker {
    height: 36px;
    &:hover,
    &.ant-picker-focused {
      border-color: $dark-1000 !important;
      box-shadow: none !important;
    }
  }
}

.ant-select.filter-select {
  height: 36px;
  width: 140px;
  &:hover .ant-select-selector,
  &.ant-select-focused .ant-select-selector {
    border-color: $dark-1000 !important;
    box-shadow: none !important;
  }
}
