@import "/src/_styleguide/variables.scss";

.apply-workflow-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
  }
  .aw-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 16px 0;
  }

  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0 !important;
    button {
      font-weight: 500 !important;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }
    .right-part {
      gap: 8px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .pp-modal-header {
    display: flex;
    padding: 16px 16px 12px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border: 1px solid #ececec;
    color: #000;
    font-family: $font-title;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    .pp-mh-icon {
      display: flex;
      height: 28px;
      width: 28px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      background: #fff;
      box-shadow:
        0px 5px 3px 0px rgba(0, 0, 0, 0.03),
        0px 2px 2px 0px rgba(0, 0, 0, 0.04),
        0px 1px 1px 0px rgba(0, 0, 0, 0.05);
      svg {
        width: 18px;
        height: 18px;
        color: #757575;
      }
    }
  }
}
