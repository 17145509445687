@import "/src/_styleguide/variables.scss";

.step-invalid {
  margin-top: 56px;
  > h2 {
    margin: 0;
    text-align: center;
  }
  .pp-avatar {
    margin-left: auto;
    margin-right: auto;
  }
  .invalid-info {
    margin: 16px 0 32px 0;
    color: #000;
    font-family: $font-title;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    b {
      font-weight: 600;
    }
  }
  button {
    color: #000;
    font-family: $font-title;
    font-weight: 500;
  }
  .invalid-box {
    text-align: center;
    padding: 32px 0px;
    border-radius: 16px;
    border: 1px solid #83d1d6;
    background: #e8f6f7;
    svg {
      width: 28px;
      height: 28px;
    }
    p {
      color: #000;
      text-align: center;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 16px 0 8px 0;
    }
    .invalid-phone {
      display: inline-block;
      padding: 8px 32px;
      border-radius: 100px;
      background: #83d1d6;
      a {
        color: #000;
        text-align: center;
        font-family: $font-title;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration: none;
      }
    }
  }
}
