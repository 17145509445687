@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.credit-select-page {
  > h2 {
    margin-bottom: 16px;
  }
  .credit-select-info {
    color: #000;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
}

.credit-box {
  margin-top: 32px;
  border-radius: 8px;
  background: #fff;
  padding: 24px;

  border: 1px solid #d6d6d6;

  .credit-box-title {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    .circle {
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      margin: 0 16px 0 0;
    }
    h5 {
      color: #000;

      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .alert-box {
    display: flex;
    padding: 16px 12px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    border-radius: 12px;
    background: #fcefbf;
    &.danger {
      border: 1px solid #f9d2ce;
      background: #fceae8;
    }
  }

  &.approved {
    border: 1px solid #c5efab;
    box-shadow: 0px 0px 0px 3px #c5efab;
    .barn-header {
      font-size: 24px;
    }
    .circle {
      background: #c5efab;
    }
    .approved-desc {
      text-align: center;
      margin-top: 24px;
      font-size: 16px;
    }
  }

  &.danger {
    border: 1px solid #fcefbf;
    box-shadow: 0px 0px 0px 3px #fcefbf;
    .barn-header {
      font-size: 24px;
    }
    .circle {
      background: #fcefbf;
    }
  }

  &.declined {
    .circle {
      background: #efc4ab;
    }
    .declined-desc {
      color: #757575;
      margin-top: 12px;
    }
    button {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        stroke-width: 1;
        margin-left: 8px;
        width: 18px;
        height: 18px;
        position: relative;
        top: -1px;
      }
    }
  }

  .credit-total {
    margin-top: 16px;
    > span {
      font-weight: 400;
      color: #9d9d9d;
    }
  }

  .bottom-actions {
    margin-top: 24px;
  }

  .credit-list {
    margin: 0 0 0 0;
    > .tradeline {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      padding: 20px 0 16px 0;
      > .account-box {
        flex: 1;
        margin-left: 16px;
        .top-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #000;
          font-family: $font-title;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .bottom-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          color: #757575;
          font-family: $font-title;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .select-actions {
          margin-top: 20px;
          text-align: right;
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
          > button {
            flex: 1;
          }
        }
      }
      &:last-child > .account-box {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.credit-select-bottom {
  width: 100%;
  button.credit-select-button {
    .unlock-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      svg {
        stroke-width: 2;
        margin-right: 8px;
        width: 18px;
        height: 18px;
        position: relative;
        top: -1px;
      }
    }
  }
  .approved-desc {
    text-align: center;
    margin: 0 0 8px 0;
    font-size: 16px;
    display: none;
  }
}

@media (max-width: $breakpoint-mobile) {
  .credit-select-bottom .approved-desc {
    display: block;
  }
  .credit-box .approved-desc {
    display: none;
  }
}
