@import "/src/_styleguide/variables.scss";

.custom-task-drawer {
  border-radius: 16px;
  overflow: hidden;
  .ant-drawer-header {
    display: none !important;
  }
  .ant-drawer-body {
    padding: 0;
    position: relative;
    .new-task-title {
      display: flex;
      padding: 16px 24px 16px 24px;
      width: calc(100% - 48px);
      align-items: center;
      background: #fff;
      gap: 8px;
      align-self: stretch;
      border-bottom: 1px solid #ececec;
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .or-bar {
      align-items: center;
      > div {
        height: 1px;
        flex: 1;
        background: #ececec;
      }
      span {
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        display: block;
        background: #fff;
        padding: 0 16px;
      }
    }
    .new-task-form {
      height: calc(100% - 60px - 73px - 48px);
      margin-top: 60px;
      overflow-y: auto;
      padding: 24px 28px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      input {
        font-size: 14px;
      }
      textarea {
        min-height: 160px;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
      }
    }
    .new-task-actions {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      margin: 0 !important;
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 32px);
      border-top: 1px solid #ececec;
      background: #fff;
      button {
        font-weight: 500 !important;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
      }
    }
  }
  .at-info {
    color: #757575;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.at-email {
  color: #757575;
  font-family: $font-title;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-select-selection-item .at-email {
  display: none;
}

.ppsd-task .ant-select-item-option-content {
  display: flex;
  align-items: center;
  color: #000;
  .pp-task-badge {
    margin-right: 8px;
  }
}

.ppsd-task {
  .ant-select-item.ant-select-item-group {
    display: flex;
    align-items: center;
    color: #000;
    font-family: $font-title;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 160% */
    letter-spacing: 0.2px;
    text-transform: uppercase;
    border-radius: 4px;
    background: #f3f3f3;
  }
  .ant-select-item-option-grouped {
    padding-inline-start: 12px !important;
    .pp-task-badge {
      display: none;
    }
  }
}

.custom-task-parent {
  .ant-drawer-content-wrapper {
    border-radius: 16px;
    height: calc(100% - 24px);
    top: 12px;
    right: 12px;
  }
}

.custom-task-parent.ant-drawer.ant-drawer-open {
  z-index: 1001 !important;
}
