@import "/src/_styleguide/variables.scss";

.pp-offer-card {
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  padding: 16px;
  background: #fff;
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
  button {
    margin: 16px 0 0 0;
  }
  .oc-info,
  .oc-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .oc-content {
    flex: 1;
    p {
      margin: 8px 0 0 0;
      color: #828282;

      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .oc-top > div {
      color: #000;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &.pp-selected .oc-info {
    align-items: flex-start;
  }
  &.pp-selected button {
    display: none !important;
  }
  &.pp-selected .oc-content p {
    color: #000;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-decoration: underline;
  }
  .oc-content p.notice {
    margin: 8px 0 0 0;
    color: #949494;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-decoration: none;
  }
  .oc-circle {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: #abebef;
    margin-right: 12px;
    svg {
      width: 16px;
      height: 16px;
      color: #000;
    }
  }
  &.duration-18 .oc-circle {
    background: #b9abef;
  }
  &.duration-12 .oc-circle {
    background: #c5efab;
  }
  .popular {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    background: #e7fbfc;
    width: 100%;
    height: 28px;
    left: 0;
    top: 0;
    position: absolute;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: #12969e;
    text-align: center;
    font-family: $font-title;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
  &.pp-popular {
    padding-top: 42px;
    border-radius: 16px;
    border: 1px solid #83d1d6;
    background: #fff;
    box-shadow: 0px 0px 0px 3px rgba(131, 209, 214, 0.2);
  }
  &.duration-18.pp-popular {
    border: 1px solid #b9abef;
    box-shadow: 0px 0px 0px 3px rgba(185, 171, 239, 0.2);
    .popular {
      background: #e9e5fa;
      color: #876bee;
    }
  }
  &.duration-12.pp-popular {
    border: 1px solid #a2d683;
    box-shadow: 0px 0px 0px 3px rgba(162, 214, 131, 0.2);
    .popular {
      background: #e0f7d2;
      color: #5b9d33;
    }
  }
  .oc-info.desktop {
    display: block;
    .oc-circle {
      margin-bottom: 24px;
      width: 40px;
      height: 40px;
      svg {
        width: 20px;
        height: 20px;
        color: #000;
      }
    }
    .flat-title {
      color: #000;
      font-family: $font-title;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }
    p {
      color: #828282;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 16px 0;
    }
    .flat-money {
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 16px;
    }
  }
}
