@import "/src/_styleguide/variables.scss";

.pp-toggle {
  background: #d7dde9;
  &:hover {
    background: #4d5b76;
  }
  &.ant-switch-checked {
    background: #090e15;
  }
  &[disabled] {
    opacity: 0.2 !important;
  }
  .ant-click-animating-node {
    display: none;
  }
  &.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #090e15;
  }
}
