@import "/src/_styleguide/variables.scss";

.pp-radio-group {
  &.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #000;
    border-color: #000;
  }
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #000;
    background: #ffffff;
    border-color: #000;
  }
  .ant-radio-button-wrapper {
    border-color: #000 !important;
    box-shadow: none !important;
    outline: 0 !important;
    span {
      font-family: $font-main;
    }
  }

  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #000;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover::before {
    background-color: #000;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: #000;
  }
}
