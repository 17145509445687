@import "/src/_styleguide/variables.scss";

.pp-avatar {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  display: flex;
  margin: 0 auto 40px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #c5efab;
  svg {
    width: 28px;
    height: 28px;
    color: #000;
  }
  &.pp-danger {
    background: #f9d2ce;
  }
  &.pp-warning {
    background: #fcefbf;
  }
}

@media (max-width: $breakpoint-tablet) {
  .pp-avatar {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    margin: 0 auto 32px auto;
    svg {
      width: 24px;
      height: 24px;
      color: #000;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .pp-avatar {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    margin: 0 auto 32px auto;
    svg {
      width: 24px;
      height: 24px;
      color: #000;
    }
  }
}
