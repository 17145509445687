@import "/src/~@centrate-io/barn/dist/variables";

.pp-datepicker {
  width: 100%;

  &.ant-picker-outlined {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    box-shadow: 0 0 0 1px #ebebea;
    outline: none !important;
    font-family: "PP Mori", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    border-radius: 6px;
  }

  &.ant-picker-outlined:hover {
    border-color: $dark-1000 !important;
    box-shadow: none !important;
  }

  &.ant-picker-large .ant-picker-input > input {
    font-size: 16px;
    line-height: 24px !important;
  }
  &.pp-invalid {
    border-color: $error !important;
  }
  &.pp-large {
    border-radius: 12px;
    height: 56px;
  }
  &.pp-medium {
    border-radius: 10px;
    height: 48px;
  }
}

.pp-datepicker-popup {
  &.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-selected:not(
      .ant-picker-cell-disabled
    )
    .ant-picker-cell-inner,
  &.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-disabled
    )
    .ant-picker-cell-inner,
  &.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-disabled
    )
    .ant-picker-cell-inner {
    color: #000;
    background: #a2d683;
  }
  a {
    color: $dark-1000;
    &:hover {
      text-decoration: underline;
    }
  }
  &.ant-picker-dropdown .ant-picker-header-view button:hover {
    color: $dark-1000;
    text-decoration: underline;
  }
  &.ant-picker-dropdown .ant-picker-footer {
    display: none !important;
  }
  &.ant-picker-dropdown .ant-picker-header-view {
    pointer-events: none !important;
  }
  &.ant-picker-dropdown .ant-picker-header-super-next-btn,
  &.ant-picker-dropdown .ant-picker-header-super-prev-btn {
    display: none !important;
  }
  &.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: $dark-1000;
  }
  &.ant-picker-dropdown
    .ant-picker-week-panel-row-range-start
    td.ant-picker-cell:before,
  &.ant-picker-dropdown
    .ant-picker-week-panel-row-range-end
    td.ant-picker-cell:before,
  &.ant-picker-dropdown
    .ant-picker-week-panel-row-selected
    td.ant-picker-cell:before {
    background: #a2d683;
  }
  &.ant-picker-dropdown
    .ant-picker-week-panel-row-range-start
    td.ant-picker-cell
    div,
  &.ant-picker-dropdown
    .ant-picker-week-panel-row-range-end
    td.ant-picker-cell
    div,
  &.ant-picker-dropdown
    .ant-picker-week-panel-row-selected
    td.ant-picker-cell
    div {
    color: $dark-1000;
  }
  &.ant-picker-dropdown
    .ant-picker-month-panel-container
    .ant-picker-header-super-next-btn,
  &.ant-picker-dropdown
    .ant-picker-month-panel-container
    .ant-picker-header-super-prev-btn {
    display: block !important;
  }
}
