@import "/src/_styleguide/variables.scss";

.pp-title {
  color: #000;
  text-align: center;
  font-family: $font-title;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 56px 0;
  text-align: left;
}

@media (max-width: $breakpoint-tablet) {
  .pp-title {
    font-size: 32px;
    margin: 0 0 48px 0;
    text-align: center;
  }
}

@media (max-width: $breakpoint-mobile) {
  .pp-title {
    font-size: 28px;
    margin: 0 0 48px 0;
    text-align: center;
  }
}
