@import "/src/~@centrate-io/barn/dist/variables";

/* PAGINATION */
.pp-table {
  &.ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }
  &.ant-table-wrapper .ant-table-column-sorter-up.active,
  &.ant-table-wrapper .ant-table-column-sorter-down.active {
    color: $dark-1000;
  }
  &.ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
  .ant-table-header {
    border-radius: 0 !important;
    table {
      border-radius: 0 !important;
    }
  }
  &.ant-table-wrapper .ant-table-thead > tr > th,
  &.ant-table-wrapper .ant-table-thead > tr > td {
    background: #fff;
    border-bottom: 1px solid #eeedec;
    border-top: 1px solid #eeedec;
    padding: 8px 12px !important;
    border-right: 1px solid #eeedec;
    &::before {
      height: 100% !important;
      display: none;
    }
    &:first-child {
      border-left: 1px solid #eeedec;
    }
    .column-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }
  .doc-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
      position: relative;
      top: -1px;
      margin-right: 6px;
    }
    .pp-tag {
      margin-left: 8px;
      margin-right: 0;
      text-transform: capitalize;
    }
  }
  .table-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
  &.ant-table-wrapper .ant-table-tbody > tr.ant-table-row > td {
    border-bottom: 1px solid #eeedec !important;
    padding: 8px 12px !important;
    border-right: 1px solid #eeedec;
    background: #fff;

    &:first-child {
      border-left: 1px solid #eeedec;
    }
  }

  &.ant-table-wrapper.barn-tbl.striped tr:nth-child(2n) td,
  &.ant-table-wrapper.barn-tbl .ant-table-tbody > tr:hover > td {
    background: #f8f7f7;
  }

  .ant-table-pagination.ant-pagination {
    margin: 24px 0 0 0;
    > li {
      border-radius: 12px;
      border-color: transparent;
    }
    > li,
    > li a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: none !important;
      font-family: "PP Mori", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #090e15;
      background: transparent;
    }
    li > a i svg {
      color: #090e15 !important;
    }
    .ant-pagination-disabled,
    .ant-pagination-disabled a {
      color: #adb5c5 !important;
      background: transparent !important;
      cursor: not-allowed !important;
      i svg {
        color: #adb5c5 !important;
      }
    }
    li:hover,
    li:hover a {
      background: #e7ecf4;
      color: #090e15;
    }
    .ant-pagination-item-active,
    .ant-pagination-item-active a {
      color: #fff !important;
      background: #090e15 !important;
    }
    .ant-pagination-prev a,
    .ant-pagination-next a {
      border-color: transparent;
    }
  }

  .ant-spin .ant-spin-dot-item {
    background-color: #090e15;
  }
}
