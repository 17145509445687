@import "/src/_styleguide/variables.scss";

.address-card {
  padding: 16px;
  flex: 1;
  position: relative;
  .ac-hover {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    z-index: 9;
  }
  &:hover {
    .ac-hover {
      display: flex;
    }
  }
  > div {
    font-family: $font-main;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #000;
    b {
      font-weight: 600;
    }
    i {
      color: #acacac;
    }
    &.ac-name,
    &.ac-address,
    &.ac-location {
      margin-bottom: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  label {
    font-family: $font-main;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #78716c;
    margin: 0 0 8px 0;
    display: block;
  }
}
