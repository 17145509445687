.mail-iframe-viewer {
  width: 100%;
  height: 359px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ddd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
