@import "/src/_styleguide/variables.scss";

.workflow-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid #ededed;
  background: #fafaf9;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 240px - 266px);
  z-index: 999;
  transition: width 0.2s;
  .cr-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    img {
      width: 28px;
      height: 28px;
      background: #fff;
      border-radius: 14px;
      border: 1px solid #cfcfcf;
      padding: 5px;
    }
  }
  .awh-back {
    width: 32px;
    > span {
      cursor: pointer;
    }
    flex: 1;
    svg {
      color: #000000;
    }
    &:hover {
      svg {
        color: #777;
      }
    }
    &.disabled > span {
      cursor: not-allowed;
    }
  }
  .pp-tag {
    position: relative;
    top: -1px;
    margin-left: 8px;
  }
  .awh-title {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 4px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    h4 {
      margin: 0;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        color: #757575;
        margin-left: 8px;
        position: relative;
        top: -1px;
        opacity: 0;
      }
      &:hover svg {
        opacity: 1;
      }
      > span {
        color: #757575;
      }
      > b {
        color: #000;
        font-weight: 600;
      }
    }
    > div {
      display: flex;
      align-items: center;
      gap: 4px;
      .awh-circle {
        width: 6px;
        height: 6px;
        min-width: 6px;
        min-height: 6px;
        border-radius: 3px;
        display: block;
        content: "";
        clear: both;
        background: #9d9d9d;
      }
      h5 {
        margin: 0;
        color: #9d9d9d;
        font-family: $font-title;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }
      &.awh-status-saved {
        .awh-circle {
          background: #5b9d33;
        }
        h5 {
          color: #5b9d33;
        }
      }
    }
  }
  .awh-actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.site-layout.collapsed {
  .site-content .workflow-header {
    width: calc(100% - 80px - 266px);
  }
}
