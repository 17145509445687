@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.settings-triggers {
  svg.tabler-icon-circle-check {
    color: $success;
  }
  svg.tabler-icon-circle-minus {
    color: $dark-200;
  }
  svg.tabler-icon-circle-x {
    color: $error;
  }
  .admin-button {
    font-size: 14px;
  }
  .actions-list {
    text-align: left;
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    gap: 4px;
    flex: 1;
    > span {
      display: inline-flex;
    }
  }
  .trigger-actions {
    gap: 8px;

    .pp-tag {
      margin: 0;
    }
    svg {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }
  .doc-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .doc-owner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    i {
      font-size: 13px;
    }
  }
  .doc-download {
    &:hover {
      text-decoration: underline;
    }
  }
  .role-column {
    text-transform: capitalize;
  }
  .ant-pagination-options {
    display: none !important;
  }
  .ant-pagination {
    margin: 0 !important;
    padding-top: 18px;
  }
  .settings-search {
    border-bottom: 1px solid #ececec;
  }
  .team-info {
    display: flex;
    align-items: center;
    gap: 12px;
    .team-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      background: linear-gradient(180deg, #000 23.56%, rgba(0, 0, 0, 0.4) 150%);
      stroke-width: 0.715px;
      stroke: #000;
      box-shadow: 0px 5.718px 5.718px 0px rgba(255, 255, 255, 0.3) inset;
      filter: drop-shadow(0px 2.859px 5.718px rgba(0, 0, 0, 0.14));
      text-align: center;
      > span {
        background: linear-gradient(180deg, #fff 44.74%, #999 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $font-main;
        font-size: 17.154px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.137px;
      }
    }
    .team-data {
      display: flex;
      flex-direction: column;
      gap: 0;
      h4 {
        margin: 0;
        color: #000;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      p {
        margin: 0;
        color: #757575;
        font-family: $font-title;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }

  // <div className="team-info">
  //         <div className="team-avatar">{name[0].toUpperCase()}</div>
  //         <div className="team-data">
  //           <h4>{name}</h4>
  //           <p>
  //             {data.user_count} Members&nbsp;&nbsp;&bull;&nbsp;&nbsp; Created{" "}
  //             {moment(data.createdAt * 1).format("MMM D, YYYY")}
  //           </p>
  //         </div>
  //       </div>
}
