@import "/src/_styleguide/variables.scss";

.mailgun-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
  }
  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    margin: 0 !important;
    button {
      font-weight: 500 !important;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }
  }
  .mailgun-verify {
    > .pp-alert-box {
      font-size: 13px;
      margin-bottom: 0 !important;
      margin-top: 16px;
    }
  }
  .domain-table {
    width: 100%;
    th,
    td {
      padding: 10px 12px;
      border-bottom: 1px solid #e7ecf4;
      text-align: left;
      white-space: nowrap;

      svg {
        width: 16px;
        height: 16px;
        &.tabler-icon-check {
          color: #4353ff;
        }
        &.tabler-icon-x {
          color: #fd8321;
        }
      }
    }
    tr > td:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        margin-left: 8px;
        display: inline-block;
      }
      input {
        display: inline-block;
        font-size: 14px;
      }
    }
    th {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #707b90;
    }
  }
}
