@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.auth-two-factor {
  min-width: 240px;
  width: 320px;
  padding: 40px;
  border-radius: 24px;
  background: #fff;
  border: 1px solid #eeedec;
  box-shadow: 0 0 100px -30px rgba(0, 0, 0, 0.1);
  .line {
    margin-bottom: 20px;
  }
  button {
    margin-top: 32px;
    font-weight: 600;
  }
  .auth-info {
    text-align: center;
    margin-bottom: 32px;
  }
  .login-logo {
    height: 28px;
  }
  .forgot {
    text-align: center;
    a {
      text-decoration: none;
    }
  }
  .channel {
    text-align: center;
    margin-top: 24px;
    svg {
      width: 14px;
      height: 14px;
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    a {
      text-decoration: none;
    }
  }
  .login-form {
    .notice {
      margin-top: 32px;
      font-weight: 400;
      font-size: 14px;
      color: $dark-400;
      text-align: center;
      font-style: italic;
    }
    > .company-select {
      .divider {
        width: 100%;
        height: 1px;
        background: #edefef;
        padding: 0 !important;
        margin: 6px 0 !important;
      }
      > div {
        cursor: pointer;
        padding: 8px 12px;
        margin: 0 -12px 0 -12px;
        border-radius: 4px;
        &:hover {
          background: #ededed;
        }
      }
      h5 {
        margin: 0;
        font-size: 16px;
      }
      p {
        margin: 4px 0 0 0;
        color: $dark-400;
        font-size: 14px;
        line-height: 14px;
        i {
          text-transform: capitalize;
        }
      }
    }
  }
}

.signout {
  position: fixed;
  top: 32px;
  right: 32px;
}
