@import "/src/_styleguide/variables.scss";

.pp-team-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background: linear-gradient(180deg, #000 23.56%, rgba(0, 0, 0, 0.4) 150%);
  stroke-width: 0.715px;
  stroke: #000;
  box-shadow: 0px 5.718px 5.718px 0px rgba(255, 255, 255, 0.3) inset;
  filter: drop-shadow(0px 2.859px 5.718px rgba(0, 0, 0, 0.14));
  text-align: center;
  > span {
    background: linear-gradient(180deg, #fff 44.74%, #999 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font-main;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  margin-right: 8px;
}
