@import '/src/~@centrate-io/barn/dist/variables';

.pp-faq {
  border-color: #D6D6D6;
  background: transparent;
  .ant-collapse-item {
    border-color: #D6D6D6;
    > .ant-collapse-header {
      color: #000;
      font-family: $font-major;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      padding: 16px 16px;
    }
  }
}
