@import "/src/_styleguide/variables.scss";

.manage-category-modal {
  .ant-modal-body {
    padding: 0 !important;
  }
  .barn-modal-body {
    display: flex;
    padding: 24px 28px 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin: 0 !important;
    > div {
      align-self: stretch;
    }
    &.body-loading {
      min-height: 200px;
    }
  }
  .mtg-preview {
    background: #fafaf9;
    margin-left: -28px;
    margin-right: -28px;
    margin-top: -24px;
    padding: 60px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    > span {
      font-weight: 400 !important;
      font-family: $font-title;
      font-size: 10px;
      font-style: normal;
      line-height: normal;
      position: absolute;
      bottom: 12px;
      right: 12px;
      color: #bbb;
    }
  }
  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0 !important;
    button {
      font-weight: 500 !important;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }
  }
  .mt-close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 32px;
    height: 32px;
  }
  .mt-add {
    text-align: center;
    justify-content: center;
    margin-top: 8px;
  }
  .mt-users {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: #fafaf9;
      padding: 16px;
      &:nth-child(odd) {
        background-color: #fafaf9;
      }

      &:nth-child(even) {
        background-color: #fff;
      }
      h4 {
        color: #000;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
      }
      p {
        color: #757575;
        font-family: $font-title;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        margin: 0;
      }
    }
  }

  // <div key={"mt-" + u.id}>
  //                 <div className="mtu-info">
  //                   <h4>{u.name}</h4>
  //                   <p>{u.email}</p>
  //                 </div>
  //                 <Button className="boxed" type="nope" size="small">
  //                   Remove
  //                 </Button>
  //               </div>
  .mt-form {
    display: flex;
    padding: 10px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #ececec;
    background: #fafaf9;
    > div {
      width: 100%;
    }
    .pp-select.pp-extra-medium {
      height: auto;
      min-height: 48px;
      .ant-select-selector {
        min-height: 48px;
      }
    }
  }
  .mt-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    .team-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 28px;
      width: 56px;
      height: 56px;
      min-width: 56px;
      min-height: 56px;
      background: linear-gradient(180deg, #000 23.56%, rgba(0, 0, 0, 0.4) 150%);
      stroke-width: 0.715px;
      stroke: #000;
      box-shadow: 0px 5.718px 5.718px 0px rgba(255, 255, 255, 0.3) inset;
      filter: drop-shadow(0px 2.859px 5.718px rgba(0, 0, 0, 0.14));
      text-align: center;
      > span {
        background: linear-gradient(180deg, #fff 44.74%, #999 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: $font-main;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      margin-bottom: 8px;
    }
    h4 {
      margin: 0;
      color: #000;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }
    p {
      margin: 0;
      color: #757575;
      font-family: $font-title;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }
  }
}
