@import "/src/_styleguide/variables.scss";

.wizard-bank-setup {
  .customer-status {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #83d1d6;
    background: #e7fbfc;
    color: #000;
    margin-bottom: 24px;

    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    p {
      margin: 0;
    }
    svg {
      color: #5ab5bb;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      min-width: 24px;
      stroke-width: 2;
    }
    b {
      font-weight: 500;
    }
    &.pending,
    &.connecting {
      border: 1px solid #cfcfcf;
      background: #ececec;
      p,
      svg {
        color: #7d7b78;
      }
    }
    &.online,
    &.bank,
    &.agreement {
      border: 1px solid #83d1d6;
      background: #e7fbfc;
      p,
      svg {
        color: #5ab5bb;
      }
    }
    &.subscribed {
      border: 1px solid #a2d683;
      background: #e0f7d2;
      p,
      svg {
        color: #5b9d33;
      }
    }
  }
}
