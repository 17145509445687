@import "/src/_styleguide/variables.scss";

.admin-application-notes {
  margin: 24px 32px 40px 32px;
  width: 100%;
  padding: 0;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  .note-compose {
    position: relative;
    textarea.pp-text-area {
      padding-right: 126px;
      padding-bottom: 64px;
    }
    button {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }
    .pp-select {
      position: absolute;
      left: 16px;
      bottom: 16px;
      width: 300px;
    }
  }
  .note-content {
    margin-top: 32px;
    .note-date {
      text-align: center;
      border-bottom: 1px solid #e7ecf4;
      margin-bottom: 32px;
      position: relative;
      > span {
        background: #f5f7fb;
        font-family: $font-title;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #000;
        position: relative;
        top: 12px;
        padding: 0 16px;
      }
    }
  }
  .application-note {
    margin-bottom: 16px;
    padding: 24px;
    background: #fff;
    border: 1px solid #ebebea;
    border-radius: 6px;
    .note-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
      gap: 16px;
      .note-user {
        flex: 1;
        font-family: $font-title;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000;
      }
      > span {
        font-family: $font-title;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        color: #000;
      }
    }
    .note-body {
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #000;
        white-space: pre-line;
        margin: 0;
      }
    }
  }
}
