@import "/src/_styleguide/variables.scss";

.create-template-modal {
  .ct-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 28px 16px 28px;

    .ct-top {
      flex: 1;
      gap: 16px;
      > div:first-child {
        flex: 1;
      }
      .ct-type {
        width: 200px;
      }
    }

    .ct-editor {
      position: relative;
      .pp-radio-group {
        position: absolute;
        top: -33px;
        right: 32px;
        z-index: 9999999;
      }
      .monaco-editor {
        border-radius: 8px !important;
        overflow: hidden;
        border: 2px solid transparent;
        box-shadow: 0 0 0 1px #ebebea;
      }
    }

    textarea {
      height: 296px;
      min-height: 296px;
      max-height: 296px;
      overflow-y: scroll !important;
    }
    .contains-html,
    .contains-variable {
      margin-left: 12px;
      svg {
        width: 18px;
        height: 18px;
      }
      &.html svg,
      &.variable svg {
        color: blue;
      }
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
    }
  }
  .pp-alert-box {
    margin-top: 8px;
    border-radius: 8px !important;
  }
}

.ppsd-task .ant-select-item-option-content {
  display: flex;
  align-items: center;
  color: #000;
  .pp-task-badge {
    margin-right: 8px;
  }
}

.vtt {
  .vtt-info {
    font-weight: 600;
  }
}
