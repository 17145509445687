@import "/src/_styleguide/variables.scss";

.step-credit-verify {
  .ssn-verify-info {
    margin: 16px 0 0 0;
    color: #000;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    b {
      font-weight: 600;
    }
  }
  .bottom-actions {
    margin-top: 0;
  }
  > h2 {
    margin-bottom: 0;
  }
  .select-ssn {
    margin: 32px 0;
    > .ssn-item {
      display: flex;
      align-items: center;
      padding: 20px 16px;
      cursor: pointer;
      background: #ffffff;
      border: 2px solid transparent;
      box-shadow: none;
      border-radius: 12px;
      margin-bottom: 1px;
      position: relative;
      > .ssn-radio {
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 18px;
        border: 2px solid #ececec;
        background: #ececec;
        min-width: 20px;
        min-height: 20px;
        margin-right: 20px;
      }
      h4 {
        margin: 0;
        flex: 1;
        color: #000;
        font-family: $font-title;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-variant-numeric: tabular-nums;
        > span {
          width: 14px;
          min-width: 14px;
          display: inline-block;
          text-align: center;
        }
        b {
          font-weight: 600;
        }
      }
      &:hover {
        border: 2px solid transparent;
        box-shadow: 0 0 0 1px #ececec;
        background: #f7f6f5;
        > .ssn-radio {
          border: 2px solid #000;
        }
      }
      &.selected {
        border: 2px solid #000000;
        box-shadow: none !important;
        > .ssn-radio {
          border: 2px solid #000;
          background: #000;
          > div {
            width: 8px;
            height: 8px;
            min-width: 8px;
            min-height: 8px;
            background: #fff;
            border-radius: 4px;
          }
        }
      }
      &:after {
        display: block;
        content: "";
        clear: both;
        width: calc(100% - 32px);
        height: 1px;
        background: #ececec;
        position: absolute;
        bottom: -3px;
        left: 16px;
      }
      &:last-child:after {
        display: none !important;
      }
    }
  }
}
