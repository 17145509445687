@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

label.barn-label {
  margin-bottom: 12px;
}

p.sub-header {
  margin-top: 16px;
}

p.input-info {
  color: #757575;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 6px;
}

input.barn-input {
  -webkit-appearance: none !important;
}

.split-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .split-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 320px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f3fdfe;
    height: 100%;
    padding: 40px;
  }
  .split-right {
    flex: 2;
  }
  .split-form {
    width: auto;
    max-width: 600px;
    background: $white;
    padding: 48px;
    border-radius: 24px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.pp-popup {
  &.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #a2d683;
    color: #000;
  }
}

.pp-link {
  font-family: $font-title;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000;
  cursor: pointer;
  border-bottom: 1px dashed #77839b;
  display: inline-block;
  &:hover {
    color: #4d5b76;
    border-bottom: 1px dashed #a7b3cb;
    text-decoration: none !important;
  }
  &.disabled {
    cursor: not-allowed;
  }
}
p.pp-form-info {
  margin: 8px 0 0 0 !important;
  color: #757575;
  font-family: $font-title;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  background: #151414;
  box-shadow: none !important;
  border-radius: 10px;
  padding: 8px 16px;
}

@media (max-width: $breakpoint-tablet) {
  .split-info {
    display: none !important;
  }
}

.barn-confirm {
  .ant-modal-confirm-body {
    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      margin-right: 12px;
      position: relative;
    }
    .ant-modal-confirm-title {
      font-family: $font-title;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000;
    }
    .ant-modal-confirm-content {
      color: #757575;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .ant-modal-confirm-btns {
    margin-top: 24px;
    > button.ant-btn {
      font-family: $font-title;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      padding: 8px 16px;
      height: 40px;
      box-shadow: none;
      border-radius: 20px !important;
    }
    button.ant-btn-primary {
      color: $light-1000;
      background: $light-0;
      border: 1px solid $light-0;
      &:hover {
        color: $light-1000;
        background: $light-50 !important;
        border: 1px solid $light-50;
      }
      &:focus {
        color: $light-1000;
        background: $light-100 !important;
        border: 1px solid $light-100;
      }
    }
  }
}

.tag-select-container {
  width: 240px;
  .ant-popover-inner {
    padding: 4px;
    overflow: hidden;
  }
  .tag-select-popover {
    > div {
      max-height: 200px;
      overflow-y: scroll;
    }
    .st-list {
      padding: 4px;
    }
    .st-cat {
      display: flex;
      align-items: center;
      color: #000;
      font-family: "Neue Haas Grotesk", "Helvetica Neue", Helvetica, Arial,
        sans-serif !important;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      border-radius: 4px;
      padding: 5px 12px;
      background: #f3f3f3;
    }
  }
}
.pp-modal-header {
  display: flex;
  padding: 16px 16px 12px 16px;
  align-items: center;
  gap: 12px;
  border: 1px solid #ececec;
  > div {
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow:
      0px 5px 3px 0px rgba(0, 0, 0, 0.03),
      0px 2px 2px 0px rgba(0, 0, 0, 0.04),
      0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    > svg {
      width: 18px;
      height: 18px;
      color: #000;
    }
  }
  > h3 {
    margin: 0 !important;
    color: #000;
    font-family: $font-title;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    > span {
      color: #757575;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      margin-left: 6px;
    }
  }
}

button.barn-btn.btn-tiny {
  font-size: 14px;
  padding: 5px 8px;
  font-weight: 400;
  height: 27px;
  border-radius: 8px;
  line-height: 18px;
  font-weight: 600 !important;
}

button.barn-btn.boxed {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  font-weight: 500;
  font-family: $font-title;
  font-size: 14px;
  font-style: normal;
  svg {
    width: 16px;
    height: 16px;
    stroke-width: 3;
  }
}

button.barn-btn.btn-blanco {
  color: #000;
  background: #fff;
  border: 1px solid #d9d9d9;
  &:hover {
    color: #000 !important;
    background: #fff !important;
    border: 1px solid #000 !important;
  }
  &:focus {
    color: #000 !important;
    background: #fff !important;
    border: 1px solid #000 !important;
  }
}

button.barn-btn.btn-nope {
  color: #db313b;
  background: #fff;
  border: 1px solid #d9d9d9;
  &:hover {
    color: #db313b !important;
    background: #fff !important;
    border: 1px solid #db313b !important;
  }
  &:focus {
    color: #db313b !important;
    background: #fff !important;
    border: 1px solid #db313b !important;
  }
}

.modal-load {
  min-height: 200px;
}
