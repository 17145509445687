@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.admin-application-email {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  > .threads {
    width: 320px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ececec;
    background: #fff;
    .threads-search {
      display: flex;
      align-items: center;
      gap: 8px;
      border-bottom: 1px solid #ececec;
      padding: 8px;
      input {
        line-height: 20px;
      }
      button {
        height: 34px !important;

      }
    }
    .thread-loading {
      color: #757575;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      padding: 32px 16px 32px 16px;
      text-align: center;
    }
  }
  > .email-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fafaf9;
    .convo-full {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .admin-application-conversation {
        padding: 4px 32px 64px 32px;
        position: absolute;
        left: 0;
        top: 73px;
        width: 100%;
        height: calc(100% - 73px);
        max-height: calc(100% - 73px);
        overflow-y: scroll;
      }
      .convo-subject {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 12px 32px 8px 32px;
        background: #fafaf9;

        > div {
          display: flex;
          padding: 12px 16px;
          align-items: center;
          border: 1px solid transparent;
          background: #fff;
          border-radius: 12px;
          color: #000;
          font-family: $font-title;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 27px;
          margin: 0;
          box-shadow:
            0 0 2px rgba(0, 0, 0, 0.12),
            0 2px 4px rgba(0, 0, 0, 0.14);
        }
      }
    }
  }
}
