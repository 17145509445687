@import "/src/_styleguide/variables.scss";

.analytics-menu {
  .am-title {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    padding: 0 16px;
    h3 {
      margin: 0 !important;
      color: #000;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      flex: 1;
    }
  }
  .ant-menu.ant-menu-light {
    background: #fff !important;
  }
}
