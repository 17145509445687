@import "/src/~@centrate-io/barn/dist/variables";

.admin-body.admin-customer {
  padding: 60px 0 0 0 !important;
  .admin-app-content {
    position: relative;
    height: calc(100vh - 106px);
    overflow-y: scroll;
  }
  .ant-menu-title-content .pp-tag {
    margin-left: 8px;
  }
  .admin-menu {
    .appl-menu {
      padding: 0 20px;
      background: #fff;

      .ant-menu > .ant-menu-item {
        padding: 0 12px !important;
        color: $dark-500;
        &:hover,
        &.ant-menu-item-selected {
          color: $dark-1000;
        }
      }
      .ant-menu-submenu-title,
      .ant-menu .ant-menu-submenu-title {
        color: $dark-500;
        &:hover {
          color: $dark-1000;
        }
      }
      .ant-menu-submenu-selected > .ant-menu-submenu-title,
      .ant-menu-submenu-selected > .ant-menu-submenu-title {
        color: $dark-1000;
      }
      .ant-menu-horizontal > .ant-menu-item::after,
      .ant-menu-horizontal > .ant-menu-item::after,
      .ant-menu-horizontal > .ant-menu-submenu::after,
      .ant-menu-horizontal > .ant-menu-submenu::after {
        inset-inline: 12px !important;
      }
      .ant-menu-horizontal > .ant-menu-submenu:hover::after,
      .ant-menu-horizontal > .ant-menu-item:hover::after,
      .ant-menu-horizontal > .ant-menu-item-selected:hover::after,
      .ant-menu-horizontal > .ant-menu-item-selected::after,
      .ant-menu-horizontal > .ant-menu-submenu-selected:hover::after,
      .ant-menu-horizontal > .ant-menu-submenu-selected::after,
      .ant-menu-horizontal > .ant-menu-submenu-active::after,
      .ant-menu-horizontal > .ant-menu-submenu-open::after {
        border-color: $dark-1000 !important;
      }
      .tab-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
    }
    svg.tabler-icon-chevron-down {
      margin-right: 0 !important;
      margin-left: 6px !important;
    }
  }
}

// .pp-tabs {
//   &.ant-tabs .ant-tabs-tab {
//     color: $dark-500;
//   }
//   &.ant-tabs .ant-tabs-tab:hover {
//     color: $dark-1000;
//   }
//   &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//     color: $dark-1000;
//     text-shadow: 0 0 0.25px #000;
//   }
//   &.ant-tabs .ant-tabs-ink-bar {
//     background: $dark-1000;
//   }
//   .tab-name {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     svg {
//       width: 16px;
//       height: 16px;
//       margin-right: 6px;
//     }
//   }
// }

.ant-menu-submenu-popup {
  .ant-menu-item-selected,
  .ant-menu .ant-menu-item-selected,
  .ant-menu-item:hover {
    background-color: #a2d683;
  }

  .ant-menu-item-selected,
  .ant-menu .ant-menu-item-selected,
  .ant-menu-item:hover {
    color: #000;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    height: 32px !important;
    line-height: 32px !important;
  }
  .ant-menu-item .tab-name {
    .pp-tag {
      margin-left: 8px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $dark-1000;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      color: $dark-1000;
    }
  }
}
