@import "/src/_styleguide/variables.scss";

.step-unqualified {
  > h2 {
    margin: 0;
  }
  .unqualified-info {
    margin: 16px 0 32px 0;
    color: #000;
    font-family: $font-title;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 150% */
    b {
      font-weight: 600;
    }
  }
  button {
    color: #000;
    font-family: $font-title;
    font-weight: 500;
  }
}
