@import "/src/_styleguide/variables.scss";

.no-tasks-pls {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto 0 auto;
  padding-top: 32px;
  img {
    max-width: 260px;
  }
  h2 {
    margin: -20px 0 8px 0 !important;
    color: #000;
    text-align: center;
    font-family: $font-title;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  p {
    margin: 0 !important;
    color: #757575;
    text-align: center;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .nt-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 24px;

    button {
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-family: $font-title;
      font-weight: 500;
      svg {
        width: 16px;
        height: 16px;
        color: #9d9d9d;
      }
    }
  }
}
