@import "/src/_styleguide/variables.scss";

.admin-application-tasks {
  margin: 24px 32px;
  .no-tasks {
    padding: 28px 16px 16px 16px;
    text-align: center;
    margin: 0 !important;
    color: #737272;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: 100%;
    font-style: italic;
  }
  .at-box {
    display: flex;
    width: 696px;
    max-width: 696px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid #cfcfcf;
    background: #fff;
    box-shadow: 0px 0px 0px 4px #f3f3f3;
    overflow: hidden;
    .at-list {
      display: flex;
      padding: 4px 24px 16px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--sds-size-space-300);
      align-self: stretch;
      > div:last-child {
        border-bottom: 0 !important;
      }
    }
    .at-header {
      display: flex;
      padding: 16px 20px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      background: #fafaf9;
      .at-tabs {
        display: flex;
        align-items: center;
        gap: 2px;
        > div {
          display: flex;
          height: 32px;
          padding: 8px 12px;
          align-items: center;
          gap: 4px;
          border: 1px solid transparent;
          cursor: pointer;
          > svg {
            width: 16px;
            height: 16px;
            color: #8f8f8f;
          }
          &.at-active,
          &:hover {
            border-radius: 10px;
            border: 1px solid #cfcfcf;
            background: #fff;
          }
        }
      }
      .tc-count {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 16px;
        height: 16px;
        color: #000;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 6px;
        background: #ececec;
        position: relative;
        top: 1px;
        border: 1px solid #ececec;
        &.todo {
          border: 1px solid #f2a8dd;
          background: #fce4f6;
          color: #96076e;
        }
      }
    }
    .at-actions {
      display: flex;
      align-items: center;
      gap: 8px;
      .btn-primary > svg {
        width: 16px;
        height: 16px;
        color: #9d9d9d;
      }
    }
  }
}
