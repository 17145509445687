@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.admin-application-conversation {
  .convo-collapsed {
    margin-bottom: 8px;
    display: flex;
    padding: 12px 16px;
    border: 1px solid #ececec;
    background: #fff;
    border-radius: 12px;
    cursor: pointer;
    &:hover,
    &.selected {
      background: #fafaf9;
    }
    .pp-user-avatar,
    .pp-company-avatar {
      margin-right: 12px;
    }
    .convo-info {
      flex: 1;
      min-width: 0;
      h5 {
        margin: 0;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide the overflowed content */
        text-overflow: ellipsis; /* Show the ellipsis when the text overflows */
        max-width: 100%;
      }
      p {
        margin: 0;
        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide the overflowed content */
        text-overflow: ellipsis; /* Show the ellipsis when the text overflows */
        max-width: 100%;
        color: #757575;
        font-family: $font-title;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .convo-date {
      margin-left: 8px;
      color: #000;
      text-align: right;
      font-family: $font-title;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-top: 4px;
    }
  }
}
