@import "/src/_styleguide/variables.scss";

.admin-wizard {
  display: flex;
  flex-direction: column;
  align-items: center;
  .admin-wizard-body {
    width: 100%;
    min-width: 400px;
    max-width: 550px;
  }
  .admin-wizard-menu {
    width: 240px;
    position: fixed;
    top: 100px;
    left: calc(50vw - 120px - (275px + 24px));
  }
}
.site-layout.collapsed .admin-wizard > .admin-wizard-menu {
  left: calc(50vw - 197px - (275px + 24px));
}
@media (max-width: 1366px) {
  .admin-wizard > .admin-wizard-menu {
    left: 264px;
  }
  .site-layout:not(.collapsed) .site-content.wizard .admin-body {
    padding-right: 24px !important;
    .admin-wizard {
      align-items: flex-start;
    }
  }
}
@media (max-width: 1208px) {
  .site-layout.collapsed .admin-wizard > .admin-wizard-menu {
    left: 104px;
  }
  .site-layout.collapsed .site-content.wizard .admin-body {
    padding-right: 24px !important;
    .admin-wizard {
      align-items: flex-start;
    }
  }
}

.wzrd-box {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #cfcfcf;
  background: #fff;
  margin-bottom: 20px;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 0px 0px 4px #f3f3f3;
  }
  .wzrd-header {
    display: flex;
    padding: 16px 24px 12px 24px;
    gap: 8px;
    border-bottom: 1px solid #ececec;
    align-items: center;
    > h3 {
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      margin: 0;
      flex: 1;
    }
    > p {
      color: #949494;
      font-family: $font-title;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
    }
    &.custom {
      cursor: pointer;
      &:hover {
        background: #f7f8f9;
      }
    }
  }
  .wzrd-body {
    padding: 24px 40px 40px 40px;
    .form-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      > div {
        flex: 1;
      }
    }
    .form-item,
    .form-row {
      margin-bottom: 16px;
    }
    .ant-checkbox-wrapper {
      margin-top: 8px;
    }
    > .app-yesno {
      border-bottom: 1px solid #ececec;
      padding-bottom: 20px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}

// &.wizard {
//   .admin-body {
//     height: calc(100vh);
//     padding: 100px 288px 146px 288px;
//     position: relative;
//     background: #fff;
//   }
//   .admin-header {
//     display: none !important;
//   }
// }

// .site-layout.collapsed {
//     .admin-wizard .admin-wizard-menu {
//     right: calc(50vw - 120px + 275px);

//     }
//   }
