@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.auth-user {
  min-width: 240px;
  width: 400px;
  padding: 40px;
  border-radius: 24px;
  background: #fff;
  border: 1px solid #eeedec;
  box-shadow: 0 0 100px -30px rgba(0, 0, 0, 0.1);
  .invite-form {
    margin-bottom: 40px;
    > div {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .pp-alert-box {
    margin-top: 24px;
  }
  > h2 {
    color: #000;
    font-family: $font-title;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
  > p {
    color: #757575;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 32px 0;
  }
}
.company-invite-logo {
  height: 24px;
  position: fixed;
  top: 24px;
  left: 24px;
}
