@import "/src/~@centrate-io/barn/dist/variables";

.pp-tag-list {
  button {
    display: inline-block !important;
    margin: 0;
    height: 24px !important;
    max-height: 24px !important;
    line-height: 24px !important;
    width: 24px !important;
    max-width: 24px !important;
    padding: 0 !important;
    border-radius: 12px;
    position: relative;
    top: 1px;
    text-align: center !important;
    justify-content: center !important;
    > svg {
      width: 12px !important;
      height: 12px !important;
    }
  }
  > div,
  button {
    margin: 2px 6px 2px 0;
    &:last-child {
      margin-right: 0;
    }
  }
}
