@import "/src/~@centrate-io/barn/dist/variables";

.admin-application-payments {
  svg.tabler-icon-circle-check {
    color: $success;
  }
  svg.tabler-icon-circle-minus {
    color: $dark-200;
  }
  svg.tabler-icon-circle-x {
    color: $error;
  }
}
