@import "/src/_styleguide/variables.scss";

.pp-bar {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  position: fixed;
  z-index: 999;
  display: flex;
  padding: var(--Space-200, 8px);
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow:
    -9px 44px 13px 0px rgba(0, 0, 0, 0),
    -6px 28px 12px 0px rgba(0, 0, 0, 0.01),
    -3px 16px 10px 0px rgba(0, 0, 0, 0.02),
    -1px 7px 7px 0px rgba(0, 0, 0, 0.03),
    0px 2px 4px 0px rgba(0, 0, 0, 0.03);
  bottom: 12px;
  right: calc((100% - 240px) / 2);
  transform: translateX(50%);
  transition: right 0.2s;
  .pp-user-avatar {
    margin-left: 8px;
    margin-right: 4px;
    cursor: default !important;
  }
  .bar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 10px;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      color: #000;
    }
    &:hover {
      background: #d9d9d9;
    }
    &.active {
      background: #000;
      svg {
        color: #fff;
      }
    }
  }
  .bar-divider {
    width: 1px;
    height: 16px;
    background: #d9d9d9;
    margin: 0 4px;
  }
}

.bar-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  gap: 8px;
  h3 {
    color: #fff;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
}

.bar-close {
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;

  svg {
    color: #9d9d9d;
    width: 20px;
    height: 20px;
  }
  &:hover {
    svg {
      color: #fff;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
}

.bar-minimize {
  display: flex;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;

  svg {
    color: #9d9d9d;
    width: 20px;
    height: 20px;
  }
  &:hover {
    svg {
      color: #fff;
    }
  }
}

.site-layout.collapsed .pp-bar {
  right: calc((100% - 80px) / 2);
}
