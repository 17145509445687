@import "/src/_styleguide/variables.scss";

.pp-texting {
  width: 550px;
  min-width: 550px;
  max-width: 550px;
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 62px 16px 20px 16px;
  box-shadow:
    0px 58px 16px 0px rgba(0, 0, 0, 0),
    0px 37px 15px 0px rgba(0, 0, 0, 0.01),
    0px 21px 13px 0px rgba(0, 0, 0, 0.02),
    0px 9px 9px 0px rgba(0, 0, 0, 0.04),
    0px 2px 5px 0px rgba(0, 0, 0, 0.04);
  bottom: 64px;
  right: calc((100% - 240px) / 2);
  transform: translateX(50%);
  transition:
    right 0.2s,
    bottom 0.3s linear,
    opacity 0.2s ease-in-out,
    padding 0.3s ease-in-out,
    max-width 0.3s ease-in-out,
    max-width 0.3s ease-in-out,
    width 0.3s ease-in-out,
    min-width 0.3s ease-in-out,
    height 0.3s ease-in-out;
  overflow: hidden;
  height: 480px;
  opacity: 1;
  &.minimizing {
    width: 0px;
    min-width: 0px;
    max-width: 0px;
    height: 0px;
    padding: 0px;
    bottom: 40px;
    opacity: 0.2;
  }
  &.minimize {
    display: none !important;
  }
  .pp-templates {
    padding-bottom: 12px;
    border-bottom: 1px dotted #cfcfcf;
  }
  .pp-select.ant-select .ant-select-selector > span {
    font-size: 14px;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }
  .texting-info {
    width: 100%;
    padding: 16px 0 12px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h3 {
      display: inline-block;
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      margin: 0 8px 0 0;
      text-align: left;
    }
  }
  .text-compose {
    position: relative;
    width: 100%;
    margin-top: 12px;

    textarea.pp-text-area {
      padding-right: 76px;
    }
    button {
      position: absolute;
      right: 8px;
      top: 7px;
    }
  }
  .dialer-close {
    display: flex;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    z-index: 999;
    background: #fff;
    &:hover {
      background: #d9d9d9;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  /* Message window styling */
  .chat-window {
    max-height: 320px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 8px 0 8px;
    margin-top: 8px;
    /* General bubble styling */
    .no-text {
      color: #777;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      user-select: none;
    }
    .message {
      max-width: 70%;
      padding: 10px;
      margin: 14px 0 10px 0;
      border-radius: 15px;
      position: relative;
      font-size: 14px;
      line-height: 18px;
      z-index: 999;
      margin-top: 14px;
      > div {
        position: absolute;
        top: -15px;
        font-size: 12px;
        line-height: 12px;
        color: #757575;
        white-space: nowrap;
        overflow: visible;
      }
    }

    /* Left (grey) bubble */
    .message.left {
      background-color: #e0e0e0;
      margin-left: 10px;
      align-self: flex-start;
    }

    /* Right (blue) bubble */
    .message.right {
      background-color: #007aff;
      color: white;
      margin-right: 10px;
      align-self: flex-end;
      > div {
        right: 10px;
      }
    }

    /* Tail for left (grey) bubble */
    .message.right {
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: 0;
        right: -8px;
        height: 20px;
        width: 20px;
        background: #007aff;
        border-bottom-left-radius: 15px;
      }

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: -10px;
        width: 10px;
        height: 20px;
        background: white;
        border-bottom-left-radius: 10px;
      }
    }

    /* Tail for right (blue) bubble */
    .message.left {
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: -7px;
        height: 20px;
        width: 20px;
        background: #e0e0e0;
        border-bottom-right-radius: 15px;
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: -10px;
        width: 10px;
        height: 20px;
        background: white;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.site-layout.collapsed .pp-texting {
  right: calc((100% - 80px) / 2);
}

.ppsd-task .ant-select-item-option-content {
  display: flex;
  align-items: center;
  color: #000;
  .pp-task-badge {
    margin-right: 8px;
  }
}
