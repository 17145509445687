@import "/src/_styleguide/variables.scss";

.pp-custom-tag {
  display: inline-block;
  color: #000;
  background: #fff;
  font-family: $font-title;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 8px;
  height: 24px;
  line-height: 24px;
  border: 0;
  padding: 0 8px;
  border-radius: 6px;
  user-select: none;
  &.pp-custom-tag-small {
    line-height: 16px;
    font-size: 12px;
  }
  &.pp-custom-tag-tiny {
    line-height: 14px;
    font-size: 10px;
  }
}
