@import "/src/~@centrate-io/barn/dist/variables";

.admin-table-tasks {
  svg.tabler-icon-circle-check {
    color: $success;
  }
  svg.tabler-icon-circle-minus {
    color: $dark-200;
  }
  svg.tabler-icon-circle-x {
    color: $error;
  }
  .filter-tasks-type {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      > div {
        margin-right: 0;
      }
    }
  }
  .table-task-badge .pp-task-badge {
    display: inline-flex;
    white-space: nowrap;
  }
  .table-status-badge .pp-tag {
    display: inline-flex;
    white-space: nowrap;
    gap: 4px;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .task-assignee-table > span {
    display: inline-flex;
    white-space: nowrap;
    gap: 6px;
    align-items: center;
    font-weight: 600;
    > div {
      margin: 0 !important;
      position: relative;
      top: -1px;
    }
  }
  .task-status-select {
    width: 160px !important;
  }
  .task-status-select .pp-tag {
    display: inline-block;
    gap: 4px;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
      margin-right: 4px;
      position: relative;
      top: 2px;
    }
  }
}

.task-status-popup {
  .pp-tag {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    svg {
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
    }
  }
}
