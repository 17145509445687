@import "/src/_styleguide/variables.scss";

.app-box {
  width: auto;
  max-width: 600px;
  background: $white;
  padding: 48px;
  border-radius: 24px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  margin-top: 40px;
  margin-bottom: 40px;
  &.split {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    background: transparent;
    border-radius: 0;
  }
  &.full {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    background: transparent;
    border-radius: 0;
  }
}

.app-form {
  .form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    > div {
      flex: 1;
    }
  }
  .form-item,
  .form-row {
    margin-bottom: 16px;
  }
  .ant-checkbox-wrapper {
    margin-top: 8px;
  }
  > .app-yesno {
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.main-logo-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 24px;
  img {
    height: 42px;
  }
}

@media (max-width: $breakpoint-mobile) {
  .app-box {
    max-width: none;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    padding: 20px 16px 176px 16px;
  }
  .main-logo-container {
    width: 100%;
    background: #fff;
    padding: 16px;
    display: none !important;
  }
  .main-logo-container.ba-version {
    width: calc(100% - 32px);
    display: flex !important;
    justify-content: center;
  }
  .app-form {
    .form-row,
    .form-item {
      flex-direction: column;
      gap: 0;
      > div {
        width: 100%;
        max-width: 100% !important;
        margin-bottom: 16px;
      }
    }
    .form-row {
      margin-bottom: 0;
    }
  }
}
