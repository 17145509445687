@import "/src/_styleguide/variables.scss";

.admin-wizard-budget {
  .budget-overview {
    display: grid;
    grid-template-columns: repeat(3, calc(33.33333% - 18px));
    grid-column-gap: 24px;
    grid-row-gap: 0px;
    > .budget-overview-item {
      padding: 12px;
      border-bottom: 1px solid #ececec;
      &.borderless {
        border-bottom: 1px solid transparent;
      }
      h5 {
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        margin: 0 0 4px 0;
      }
      p {
        color: #000;
        font-family: $font-title;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
        text-transform: uppercase;
        margin: 0;
      }
      &.green > p {
        color: green;
      }
      &.red > p {
        color: red;
      }
    }
  }
  .budget-item {
    margin-bottom: 16px;
    h5 {
      color: #000;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 8px 0;
    }
  }
  .budget-list {
    display: flex;
    align-items: stretch;
    margin-top: 32px;
    > div {
      flex: 1px;
      .bl-title {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 24px;
        > svg {
          width: 24px;
          height: 24px;
          border-radius: 8px;
          background: #e0f7d2;
          color: #4a9b19;
          padding: 4px;
        }
      }
      &.budget-expenses .bl-title > svg {
        background: #f5d1d1;
        color: #db313b;
      }
    }
    > div.budget-sep {
      width: 1px;
      max-width: 1px;
      background: #ececec;
      margin: 0 24px;
    }
  }
}

.budget-pop {
  padding: 0;
  border-radius: 4px !important;
  .ant-select-item.ant-select-item-group {
    padding: 0;
  }
  .ant-select-item.ant-select-item-option {
    margin: 0 4px 0 4px;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    padding: 8px 12px;
    &.ant-select-item-option-active {
      background-color: #e0f7d2;
    }
  }
  .rc-virtual-list-holder-inner {
    > .ant-select-item.ant-select-item-group {
      margin-top: 0;
      margin-bottom: 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .budget-pop-title {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      width: 16px;
      height: 16px;
      &.tabler-icon-trending-up {
        color: #4a9b19;
      }
      &.tabler-icon-trending-down {
        color: #db313b;
      }
    }
  }
}
