@import "/src/_styleguide/variables.scss";
@import "/src/~@centrate-io/barn/dist/variables";

.pp-filter-tags {
  width: 180px !important;
  margin-right: 12px;
  &.ant-select {
    height: 34px;
    width: 140px;
    position: relative;
    top: 1px;
    .ant-select-selection-item {
      position: relative;
      top: 1px;
      label {
        display: none !important;
      }
    }
    .ant-select-selector > span {
      font-size: 14px !important;
    }
    &:hover .ant-select-selector,
    &.ant-select-focused .ant-select-selector {
      border-color: $dark-1000 !important;
      box-shadow: none !important;
    }
  }
}

.pp-filter-tags-dropdown {
  &.ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #a2d683;
    color: #000;
  }
  .pp-checkbox {
    margin-right: 8px;
  }
  .ant-select-item-option-content {
    overflow: visible !important;
  }
  .ant-select-item.ant-select-item-group {
    display: flex;
    align-items: center;
    color: #000;
    font-family: "Neue Haas Grotesk", "Helvetica Neue", Helvetica, Arial,
      sans-serif !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    border-radius: 4px;
    background: #f3f3f3;
  }
  .ant-select-item-option-grouped {
    padding-inline-start: 12px !important;
  }
}
