@import "/src/_styleguide/variables.scss";

.confirm-iw {
  .pp-window-box {
    background: #f8f6f2;
    max-width: 600px;
  }
  #contract-terms-pdf {
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif !important;
  }
  button {
    margin-top: 24px;
    color: #000 !important;
    font-family: $font-title;
    font-weight: 500;
  }
  p.final-terms {
    color: #000;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    b {
      font-weight: 500;
    }
    a {
      font-weight: 500;
      color: #000;
    }
  }
  .pp-terms-scroll {
    display: block;
    max-height: 420px;
    padding: 16px 12px 16px 16px;
    border-radius: 8px;
    border: 1px solid #e8e3d8;
    overflow: scroll;
    margin-bottom: 16px;
    background: #fff;
  }
}
