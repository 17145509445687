@import "/src/_styleguide/variables.scss";

.no-workflows {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	max-width: 400px;
	img {
		max-width: 260px;
	}
	h2 {
		margin: -20px 0 8px 0 !important;
		color: #000;
		text-align: center;
		font-family: $font-title;
		font-size: 28px;
		font-style: normal;
		font-weight: 600;
		line-height: 38px;
	}
	p {
		margin: 0 !important;
		color: #757575;
		text-align: center;
		font-family: $font-title;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}
	button {
		margin-top: 24px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		gap: 6px;
		font-family: $font-title;
		font-weight: 500;
		svg {
			width: 16px;
			height: 16px;
			color: #9d9d9d;
		}
	}
}
