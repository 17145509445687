@import "/src/_styleguide/variables.scss";

.actions-modify-modal {
  .ant-modal-body {
    padding: 0 !important;
    min-height: 200px;
  }
  .pp-modal-header {
    display: flex;
    padding: 16px 16px 12px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border: 1px solid #ececec;
    color: #000;
    font-family: $font-title;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    .pp-mh-icon {
      display: flex;
      height: 28px;
      width: 28px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      background: #fff;
      box-shadow:
        0px 5px 3px 0px rgba(0, 0, 0, 0.03),
        0px 2px 2px 0px rgba(0, 0, 0, 0.04),
        0px 1px 1px 0px rgba(0, 0, 0, 0.05);
      svg {
        width: 18px;
        height: 18px;
        color: #db313b;
      }
      &.warning {
        svg {
          color: #eeaf0d;
        }
      }
    }
  }
  .ba-content {
    p {
      margin: 0 !important;
    }
    .ba-desc {
      margin-top: 32px;
    }
    b {
      font-weight: 500 !important;
    }
  }
  .type-confirm {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    background: #fce8e8;
    .pp-input-wrapper {
      width: 100%;
      input {
        &:hover,
        &:focus {
          border: 1px solid #db313b !important;
          background: #fff !important;
          box-shadow: 0px 0px 0px 3px rgba(219, 49, 59, 0.1) !important;
        }
      }
    }
  }
  .barn-modal-body {
    display: flex;
    padding: 16px 28px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin: 0 !important;
    color: #000;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .barn-modal-actions {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0 !important;
    button {
      font-weight: 600 !important;
      flex: 1;
      margin: 0 !important;
    }
  }
}
