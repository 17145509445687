@import "/src/_styleguide/variables.scss";

.pp-script {
  display: none;
  margin-top: 16px;
  &.visible {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .ant-select {
    width: 100%;
  }
  .pp-select.ant-select .ant-select-selector > span {
    font-size: 14px;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }
  .ql-container {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.ppsd-task .ant-select-item-option-content {
  display: flex;
  align-items: center;
  color: #000;
  .pp-task-badge {
    margin-right: 8px;
  }
}
