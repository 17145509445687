@import "/src/_styleguide/variables.scss";

.wzrd-box.bank-new-box {
  p.bank-info {
    color: #000;
    font-family: $font-title;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 0;
    b {
      font-weight: 500;
    }
  }
  .wzrd-body {
    padding-top: 24px;
  }
  .bb-contact {
    padding: 0 20px 20px 20px;
    margin-top: 24px;
    .ant-tabs-nav-list {
      width: 100%;
      > div {
        width: 50%;
        margin: 0;
        justify-content: center;
        font-weight: 600;
      }
    }
    .ant-tabs .ant-tabs-ink-bar {
      height: 3px !important;
    }
    .ant-tabs.ant-tabs-top > .ant-tabs-nav::before {
      border-color: #ececec;
    }
    .ant-tabs-nav {
      margin-bottom: 24px;
      margin-left: 0 !important;
      margin-right: 0 !important;
      background: transparent !important;
    }
  }
  .wizard-send {
    .ws-actions {
      text-align: right;
      margin-top: 12px;
      position: relative;
      button.green-btn {
        font-weight: 500;
        height: 35px;
        border-radius: 17.5px;
      }
      .ws-toggle {
        float: left;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #000;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        height: 35px;
      }
    }
  }
}
