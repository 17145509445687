@import "/src/_styleguide/variables.scss";

.bar-search {
  width: 340px;
  max-width: 340px;
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 24px 32px 32px 32px;
  gap: 12px;
  box-shadow:
    0px 58px 16px 0px rgba(0, 0, 0, 0),
    0px 37px 15px 0px rgba(0, 0, 0, 0.01),
    0px 21px 13px 0px rgba(0, 0, 0, 0.02),
    0px 9px 9px 0px rgba(0, 0, 0, 0.04),
    0px 2px 5px 0px rgba(0, 0, 0, 0.04);
  bottom: 64px;
  right: calc((100% - 240px) / 2);
  transform: translateX(50%);
  transition: right 0.2s;
  h4 {
    color: #000;
    font-family: $font-title;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    margin: 0 0 0 0;
    text-align: left;
  }
  .or-bar {
    display: flex;
    align-items: center;
    > div {
      height: 1px;
      flex: 1;
      background: #ececec;
    }
    span {
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      display: block;
      background: #fff;
      padding: 0 16px;
    }
  }
  .ant-select-selection-placeholder {
    font-size: 14px !important;
  }
  .dialer-close {
    display: flex;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    z-index: 999;
    background: #fff;
    &:hover {
      background: #d9d9d9;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.site-layout.collapsed .bar-search {
  right: calc((100% - 80px) / 2);
}
